import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress_list } from "../contracts.js";
import { format_ether, getv, iso, nils } from "../../utils/utils.js";

const getgasinfo = async () => ({});

class Contract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  async buyLootbox(qty, token, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.buyLootbox(qty, token, info);
    return tx;
  }

  async openLootbox(qty, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.openLootbox(qty, info);
    return tx;
  }

  async accountOwns(account) {
    let resp = await this.contract.balanceOf(account, 1);
    return Number(resp);
  }
}

const chain_mode = "main";
const k = "factionsagentsbox";

const get_contract_address = () => {
  return contractAddress_list[k];
};

const get_contract = async (ext = {}) => {
  const contractAddress = get_contract_address();
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new Contract({ contractAddress, provider, signer });
  return runner;
};

const FactionsAgentsBox = {
  k,
  get_contract,
  get_contract_address,
};
export default FactionsAgentsBox;
