import React, { useEffect, useRef } from "react";
import { Card } from "../components/utilityComps.js";
import { Helmet } from "react-helmet-async";

function Tokenomics() {
  const iframeref = useRef(null);
  useEffect(() => {
    const iframe = iframeref.current;
    const handleIframeLoad = () => {
      console.log("handleIframeLoad", iframe);
      setTimeout(() => {
        console.log(iframe.contentWindow)
      }, 15 * 1e3);
      //   // Access the document inside the iframe
      //   const iframeDocument =
      //     iframe.contentDocument || iframe.contentWindow.document;
      //   console.log(iframeDocument);
    };
    // // Attach the load event listener to the iframe
    iframe.addEventListener("load", handleIframeLoad);
    return () => {
      // Clean up the event listener when the component unmounts
      iframe.removeEventListener("load", handleIframeLoad);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Tokenomics || DNA Racing</title>
      </Helmet>
      <div className="grid md:grid-cols-2 xs:grid-cols-1 gap-2">
        <div className="p-4" >
          <>
            <div class="h-[2rem]"></div>
            <p class="head-text">The Economy</p>
            <div class="h-[2rem]"></div>
            <p class="base-text">
              The goal of the economy is to create a growing and self-sustaining
              Web3 game ecosystem. This will be achieved through a token economy
              with multiple incentive structures.
            </p>
            <div class="h-[2rem]"></div>
            <p class="base-text">The core loops of this include:</p>
            <ul class="pre-base-text list-disc list-inside">
              <li>
                Rewarding users for interacting with the network. The more you
                interact with the network, the more you will be rewarded.
              </li>
              <li>Using jackpot systems to drive traffic.</li>
              <li>Balancing traffic with dynamic rake systems.</li>
              <li>
                Building "common wealth" through a bonding curve. Holding this
                common wealth will be rewarded in perpetuity by the game.
              </li>
              <li>
                Giving early adopters some first mover advantages, but not
                allowing them to ruin the economy long term.
              </li>
              <li>Creating multiple layers of micro earning potential.</li>
            </ul>
            <div class="h-[2rem]"></div>
            <p class="base-text">
              In short, the economy will reward those who drive, run, and build
              it. The longer the game is around, the more those who have built
              it will be rewarded.
            </p>
          </>
        </div>
        <Card
          className={
            "my-2 w-full min-h-[50vh] h-[90vh] overflow-hidden p-0 mx-auto bg-trasparent"
          }
        >
          <iframe
            ref={iframeref}
            style={{
              width: "calc(100% + 5rem)",
              height: "calc(100% + 4rem)",
              marginTop: "-4rem",
              marginLeft: "-5rem",
            }}
            className=""
            src="https://miro.com/app/board/uXjVM6-N-Xo=/"
            frameborder="0"
          ></iframe>
        </Card>
      </div>
    </div>
  );
}

export default Tokenomics;
