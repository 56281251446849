import Web3 from "web3";

const contract = {
  WETH: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
  ZED: "0x5ec03c1f7fa7ff05ec476d19e34a22eddb48acdc",
};

export const transfer_tokens = async ({ reciever, amt, token }) => {
  if (typeof window.ethereum === "undefined") {
    throw new Error("Please install MetaMask.");
    return;
  }

  await window.ethereum.enable();

  const web3 = new Web3(window.ethereum);

  const accounts = await web3.eth.getAccounts();
  const fromaddress = accounts[0];

  const tokenAddress = contract[token];
  const tokenABI = [
    {
      constant: false,
      inputs: [
        {
          name: "_to",
          type: "address",
        },
        {
          name: "_value",
          type: "uint256",
        },
      ],
      name: "transfer",
      outputs: [
        {
          name: "",
          type: "bool",
        },
      ],
      type: "function",
    },
  ];
  const tokendecimals = 18;
  const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);

  const recipientAddress = reciever;
  const amount = web3.utils.toWei(amt, "ether");

  let gasLimit = 50000;
  let gasPrice = await web3.eth.getGasPrice();
  gasPrice = web3.utils.toHex(Math.floor(Number(gasPrice) * 1.5));

  let payload = {
    from: fromaddress,
    nonce: 10,
    gasPrice,
    gasLimit: web3.utils.toHex(gasLimit),
  };

  let txnresp = await tokenContract.methods
    .transfer(recipientAddress, amount)
    .send(payload);

  return txnresp;
};
