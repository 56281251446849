import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress_list } from "../contracts.js";
import { format_ether, nils, tofeth } from "../../utils/utils.js";

const getgasinfo = async () => ({});

class SkinLootbox_xmax2023_Contract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(
      this.contractAddress,
      this.abi,
      this.signer,
    );
  }

  async setToken(token, tokenAddress, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setToken(token, tokenAddress, info);
    const resp = await tx.wait();
    return resp;
  }

  async setPrice(token, boxtype, price, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setPrice(token, boxtype, price, info);
    const resp = await tx.wait();
    return resp;
  }
  async getPrice(token, boxtype) {
    return await this.contract.getPrice(token, boxtype);
  }

  async setFundswallet(_fundswallet, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setFundswallet(_fundswallet, info);
    const resp = await tx.wait();
    return resp;
  }

  async mintLootbox(to, qty, boxtype, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.o_mintLootbox(to, qty, boxtype).send(info);
    const resp = await tx.wait();
    return resp;
  }

  async buyLootbox(qty, token, boxtype, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.buyLootbox(qty, token, boxtype, info);
    const resp = await tx.wait();
    return resp;
  }

  async burnLootbox(account, qty, boxtype, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.o_burnLootbox(account, qty, boxtype, info);
    const resp = await tx.wait();
    return resp;
  }

  async openLootbox(qty, boxtype, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    console.log("openLootbox", qty, boxtype, this.contract.openLootbox);
    const tx = await this.contract.openLootbox(qty, boxtype, info);
    const resp = await tx.wait();
    return resp;
  }

  async accountOwns(account, boxtype) {
    return await this.contract.accountOwns(account, boxtype);
  }

  async getParsedLogs(logs) {
    if (!logs) return [];

    let logrows = [];
    for (let log of logs) {
      let par = this.contract.interface.parseLog(log);
      // console.log("par", log, par);

      if (nils(par)) continue;

      let name = par.name;
      let o = {
        name,
        logIndex: log.logIndex,
        hash: log.transactionHash,
        blockNumber: log.blockNumber,
      };
      o.id = `${o.hash}:${o.logIndex}`;

      if (name == "MintLootboxEvent") {
        // event MintLootboxEvent( address vault, uint256 qty, uint boxtype, uint totpaid, string token);
        let a = par.args;
        o.args = {
          vault: a[0].toLowerCase(),
          qty: Number(a[1]),
          boxtype: Number(a[2]),
          totpaid: tofeth(a[3]),
          token: a[4],
        };
      } else if (name == "OpenLootboxEvent") {
        // event OpenLootboxEvent(address vault, uint256 qty, uint boxtype);
        // console.log(par.args)
        let a = par.args;
        o.args = {
          vault: a[0],
          qty: Number(a[1]),
          boxtype: Number(a[2]),
        };
      }
      logrows.push(o);
    }
    return logrows;
  }

  async withdrawFunds(to, amt, token, extinfo = {}) {
    let gasinfo = await getgasinfo();
    let info = { ...gasinfo, ...extinfo };
    let tx = await this.contract.withdrawFunds(to, amt, token, info);
    let resp = await tx.wait();
    return resp;
  }

  async destroy() {
    const gasinfo = await getgasinfo();
    const tx = await this.contract.destroy(gasinfo);
    let resp = await tx.wait();
    console.log("destroyed", resp);
    return resp;
  }
}

const get_skinlootbox_xmas2023_address = () =>
  contractAddress_list["skinlootboxxmas2023"];

const get_SkinLootbox_xmax2023_Contract = async (ext = {}) => {
  const contractAddress = get_skinlootbox_xmas2023_address();
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new SkinLootbox_xmax2023_Contract({
    contractAddress,
    provider,
    signer,
  });
  return runner;
};

export const SkinLootbox_xmax2023 = {
  class: SkinLootbox_xmax2023_Contract,
  get_contract: get_SkinLootbox_xmax2023_Contract,
  get_address: () => get_skinlootbox_xmas2023_address(),
};
