const ethers = require("ethers");
const abi = require("./abi.json");

const { contractAddress_list } = require("../contracts.js");

const getgasinfo = async () => ({});

class SkinLootbox_v1_101_Contract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  async setPrice(_price, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setPrice(_price, info);
    return tx;
  }

  async setURI(newuri, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setURI(newuri, info);
    return tx;
  }

  async getURI() {
    return await this.contract.getURI();
  }

  async set_maxboxes(_maxboxes, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.set_maxboxes(_maxboxes, info);
    return tx;
  }

  async o_mintLootbox(to, qty, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.o_mintLootbox(to, qty, info);
    return tx;
  }

  async buyLootbox(qty, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.buyLootbox(qty, info);
    return tx;
  }

  async o_burnLootbox(account, qty, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.o_burnLootbox(account, qty, info);
    return tx;
  }

  async openLootbox(qty, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.openLootbox(qty, info);
    return tx;
  }

  async getCounts() {
    let maxboxes = Number(await this.contract.maxboxes());
    let minted = Number(await this.contract.minted());
    let burned = Number(await this.contract.burned());
    let available = maxboxes - minted;
    let closed = minted - burned;
    return {
      maxboxes,
      minted,
      burned,
      available,
      closed,
    };
  }

  async getPrice() {
    let price = await this.contract.price();
    return price;
  }

  async accountOwns(account) {
    return await this.contract.accountOwns(account);
  }

  async viewFunds() {
    return await this.contract.viewFunds();
  }

  async withdrawFunds(toAddress, amount, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.withdrawFunds(toAddress, amount, info);
    return tx;
  }
}

const get_SkinLootbox_v1_101_Contract = async () => {
  const chain_mode = "polygonmain";
  const contractAddress = contractAddress_list.skinlootboxv1_101;
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const runner = new SkinLootbox_v1_101_Contract({
    contractAddress,
    provider,
    signer,
  });
  return runner;
};

const SkinLootbox_v1_101 = {
  class: SkinLootbox_v1_101_Contract,
  get_contract: get_SkinLootbox_v1_101_Contract,
};

module.exports = SkinLootbox_v1_101;
