const o = {
  o_burnLootbox:
    "function o_burnLootbox(address account, uint256 qty, uint256 boxtype)",
  buyLootbox: "function buyLootbox(uint256 qty) public",
  openLootbox: "function openLootbox(uint256 qty) external",
  accountOwns:
    "function accountOwns(address account) external view returns (uint256)",
  getPrice: "function getPrice() external view returns (uint256)",
  getCounts:
    "function getCounts() external view returns (uint256, uint256, uint256)",
};

export default o;
