import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress_list } from "../contracts.js";
import { format_ether, nils, tofeth } from "../../utils/utils.js";
import _ from "lodash";

const getgasinfo = async () => ({});

const tokenaccuracy = 1e4;
class Contract {
  constructor({ contractAddress, provider, signer, nosigner = false }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    if (nosigner === true)
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        provider,
      );
    else
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        signer,
      );
  }

  // function buyPass(string memory name, uint256 qty, string memory token) public {
  async buyPass(name, qty, token, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.buyPass(name, qty, token, info);
    console.log(`>passsdirectv1:buyPass`, tx.hash);
    const resp = await tx.wait();
    return resp;
  }

  async getParsedLogs(logs) {
    if (!logs) return [];

    let logrows = [];
    for (let log of logs) {
      let par = this.contract.interface.parseLog(log);
      if (nils(par)) continue;
      let name = par.name;
      let o = {
        name,
        logIndex: log.logIndex,
        hash: log.transactionHash,
        blockNumber: log.blockNumber,
      };
      o.id = `${o.hash}:${o.logIndex}`;

      // emit BuyPassEvent(vault, name, qty, totcost, token);
      if (name == "BuyPassEvent") {
        let [vault, name, qty, amt, token] = par.args;
        vault = vault.toLowerCase();
        amt = tofeth(amt);
        qty = Number(qty);
        o.args = { vault, name, qty, amt, token };
      }

      logrows.push(o);
      // console.log(o);
    }
    return logrows;
  }
}

const chain_mode = "main";
const name = "seasonpass";

const get_contract_address = () => {
  const contractAddress = contractAddress_list[name];
  return contractAddress;
};

const get_contract = async (ext = {}) => {
  const contractAddress = get_contract_address();
  const provider = !nils(ext.rpc)
    ? new ethers.JsonRpcProvider(ext.rpc)
    : new ethers.BrowserProvider(window.ethereum);
  const signer = ext.nosigner === true ? null : await provider.getSigner();

  const pars = { contractAddress, provider, signer, ...ext };
  const runner = new Contract(pars);
  return runner;
};

export const SeasonPass = {
  class: Contract,
  get_contract: get_contract,
  get_contract_address,
};
