import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress_list } from "../contracts.js";
import { nils } from "../../utils/utils";

const getgasinfo = async () => ({});

class SkinCreditsContract {
  constructor({ contractAddress, provider, signer, nosigner = false }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    if (nosigner === true)
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        provider,
      );
    else
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        signer,
      );
  }

  async setCreditsContract(_creditsCon, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setCreditsContract(_creditsCon, info);
    const resp = await tx.wait();
    return resp;
  }

  async setGameAddress(_gameAddress, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setGameAddress(_gameAddress, info);
    const resp = await tx.wait();
    return resp;
  }

  async balanceOf(account) {
    const balance = await this.contract.balanceOf(account);
    // console.log(`Balance of ${account}: ${balance.toString()} tokens`);
    return balance;
  }
}

const name = "sgc";
const get_contract_address = () => contractAddress_list[name];

const get_SkinCreditsContract = async (ext = {}) => {
  const contractAddress = get_contract_address();
  const provider = !nils(ext.rpc)
    ? new ethers.JsonRpcProvider(ext.rpc)
    : new ethers.BrowserProvider(window.ethereum);
  const signer = ext.nosigner === true ? null : await provider.getSigner();
  const pars = { contractAddress, provider, signer, ...ext };
  const runner = new SkinCreditsContract(pars);
  return runner;
};

export const SkinCredits = {
  class: SkinCreditsContract,
  get_contract: get_SkinCreditsContract,
};
