export const metamaskimg =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png";

export const backdropimg =
  "https://img.freepik.com/premium-photo/abstract-futuristic-with-glowing-neon-light-grid-line-pattern-background-technology-style_79161-851.jpg";

export const boximg = "https://i.imgur.com/5LJPt3M.png";
export const skinboximg = "https://i.imgur.com/0qcVE6t.png";

export const polybase = "https://mumbai.polygonscan.com";
export const polyimg = "https://polygonscan.com/images/svg/brands/matic.svg";

export const polychainimg =
  "https://polygonscan.com/assets/generic/html/favicon-light.ico";
export const wethimg =
  "https://www.marketbeat.com/logos/cryptocurrencies/weth-WETH.png?v=2023-08-07";

export const get_skin_transparent_img = (skin) => {
  return `https://dna-run-public.s3.us-east-2.amazonaws.com/fbike-skins/${skin}/${skin}.png`;
};
