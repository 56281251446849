import _ from "lodash";
import React from "react";
import { twMerge } from "tailwind-merge";
import { nils } from "../utils/utils.js";
import { Array1D_List, Array2D_Table } from "../components/utilityComps.js";
import { Helmet } from "react-helmet-async";

function MoreInfo() {
  return (
    <>
      <Helmet>
        <title>More Info || DNA Racing</title>
      </Helmet>
      <div className="home-container">
        <div class="h-[2rem]"></div>
        <p class="head-text">Introduction:</p>
        <p class="base-text">
          In the following sections we aim to detail the DNA Racing economy, a
          self-perpetuating Web3 game ecosystem. Our strategy involves
          constructing this network via the introduction of a token economy,
          designed to bolster multiple interwoven incentive structures.
        </p>

        <div class="h-[2rem]"></div>
        <p class="head-text">The Game:</p>
        <p class="mid-head-text">Ecosystem:</p>
        <p class="base-text">
          DNA Racing (Diverse Network Attributes) is an ecosystem of racing
          games.
        </p>

        <p class="base-text">
          Each NFT created represents a unique asset in each of the varying
          ecosystems.
        </p>

        <p class="base-text">
          When a new ecosystem is launched, it is pre-populated with the NFTs
          that have already been created. However, the skills and attributes of
          the assets are completely different between each ecosystem. This means
          that each NFT will always retain some level of future usefulness.
        </p>

        <p class="mid-head-text">Class System:</p>
        <p class="base-text">
          The competitions inside DNA Racing operate within a structured ranking
          system, determined by respective success rates. Winning boosts class
          levels, while losing leads to demotion. This class system is also
          influenced by the distance factor. Hence, an asset could
          simultaneously hold the top position in Class 1 for Distance A, while
          being ranked #234,000 in Class 5 for Distance B.
        </p>

        <p class="mid-head-text">Learner Classes:</p>
        <p class="base-text">
          During an asset's initial 100 competitions, it's given the opportunity
          to participate in a beginner's category. This learner class also
          encompasses "claiming races", presenting an additional layer of
          competition for these nascent assets.
        </p>

        <p class="mid-head-text">Open Class:</p>
        <p class="base-text">
          There exists an inclusive competition tier where any asset, provided
          it belongs to the same ecosystem, can compete against any other. We
          anticipate that this unrestricted class level will host the most
          intense and high-level competitions.
        </p>

        <div class="h-[2rem]"></div>
        <p class="mid-head-text">Playing Pieces:</p>
        <p class="base-text">
          There is one type of playing piece inside the FBike ecosystem.
        </p>

        <p class="mid-head-text">Levels of Genesis Assets:</p>
        <p class="base-text">
          There are 4 levels of Genesis assets with 2 additional levels inside
          each.
        </p>
        <Array2D_Table
          ar={[
            ["GENERATION", "ELEMENT"],
            ["F1", "METAL"],
            ["F2", "METAL"],
            ["F3", "FIRE"],
            ["F4", "FIRE"],
            ["F5", "EARTH"],
            ["F6", "EARTH"],
            ["F7", "WATER"],
            ["F8", "WATER"],
          ]}
        />
        <p className="base-text">
          F1 Assets have, on average, more strength than F8+ Assets.
        </p>

        <>
          <div class="h-[2rem]"></div>
          <p class="head-text">Skills:</p>
          <div class="h-[2rem]"></div>
          <p class="mid-head-text">Distance Preference:</p>
          <p class="base-text">
            Each asset has a distance preference where it performs optimally.
          </p>

          <div class="h-[2rem]"></div>
          <p class="mid-head-text">Strength:</p>
          <p class="base-text">
            Each asset has a base level of ability known as strength. Strength
            dictates the ability to always be competitive.
          </p>

          <div class="h-[2rem]"></div>
          <p class="mid-head-text">Power:</p>
          <p class="base-text">
            Each asset has a variable sprint rate known as its power. Power
            impacts the ability to break from a pack and top-end speed.
          </p>

          <div class="h-[2rem]"></div>
          <p class="mid-head-text">Temperament:</p>
          <p class="base-text">
            Each asset has a performance variable known as Temperament. This
            changes season to season and is always consistent within the season.
            Some seasons it may be best to not race some assets at all, and some
            seasons your asset may have its lifetime peak. Player management of
            temperament and solving temperament each season will separate the
            best players from the rest.
          </p>
        </>

        <>
          <div class="h-[2rem]"></div>
          <p class="head-text">DNA Racing Lifecycle</p>
          <div class="h-[2rem]"></div>
          <p class="base-text">
            All assets have a finite life of 1000 races, moving through four
            seasons of their lifecycle
          </p>
          <div class="h-[2rem]"></div>
          <p class="base-text">The aging algorithm operates as follows:</p>

          <Array2D_Table
            ar={[
              ["AGING PHASE", "RACE COUNT", "RACE LIMIT PER 24 HOURS"],
              ["SPRING", "0-250", 9],
              ["SUMMER", "251-500", 6],
              ["AUTUMN", "501-750", 4],
              ["WINTER", "751-1000", 2],
            ]}
          />
          <p className="base-text">
            The race lifespan of an asset can be extended by burning $LC tokens.
            The exact scale for this extension, which will be on a degrading
            basis, is yet to be confirmed.
          </p>
        </>

        <>
          <div class="h-[2rem]"></div>
          <p class="head-text">Maiden Events</p>
          <div class="h-[2rem]"></div>
          <p class="base-text">
            There are Maiden races for unraced NFT's. These happen in Week 1 and
            Week 5 every season.
          </p>
          <div class="h-[2rem]"></div>
          <p class="head-text">Release notes</p>
          <div class="h-[2rem]"></div>
          <p class="base-text">
            Release notes will be published during the first week of each season
            to inform of any alterations, errors, or changes to the event or
            algorithms. There will be changes from season to season, and this is
            to be expected in order to balance the ecosystem.
          </p>
        </>

        <>
          <div class="h-[2rem]"></div>
          <p class="head-text">Jackpots:</p>
          <div class="h-[2rem]"></div>
          <p class="base-text">
            The ecosystem is sustained by a continuously growing jackpot system,
            financed by the entry fees from paid events.
          </p>
          <p class="base-text">
            Three kinds of jackpots exist: Minor, Maximum, and Major Jackpots.
          </p>
          <p class="base-text">
            Each Jackpot is given a random triggering number after every game.
            1% of every player's race entry fees contributes to the jackpot
            pools:
          </p>
          <div className="ml-[3rem]">
            <ul className="list-disc list">
              {[
                "The Minor Jackpot initiates with a random figure ranging from $0 to $100.",
                "The Maximum Jackpot starts with a random number that falls between $0 and $10,000.",
                "The Major Jackpot kicks off with a value between $0 and $10,000, but this maximum increases twofold each time it's triggered.",
                "Every player incrementally raises the jackpots based on their race entry fees.",
                "Multiple jackpots can be activated during the same roll. Upon a player triggering a Jackpot, that specific Jackpot resets to $0 after choosing a new random number. All the Jackpots are utilized to purchase $LC, which is then divided (40% / 35% / 25%) among the specific player, Genesis asset owners, and $GOD token holders respectively.",
              ].map((d) => {
                return <li className="base-text">{d}</li>;
              })}
            </ul>
          </div>
        </>

        <>
          <div class="h-[2rem]"></div>
          <p class="head-text">The House Edge</p>
          <div class="h-[2rem]"></div>
          <p class="base-text">
            Rake is variable for every event and will be controlled by a ‘live
            ops’ function to drive race traffic where each ecosystem needs it.
          </p>
          <div class="h-[2rem]"></div>
          <p class="base-text">
            For every $1 entry fee on the platform, the distributions will flow
            as follows:
          </p>

          <Array2D_Table
            ar={[
              ["Player Race Entry", "$VARIABLE"],
              ["Players Returned", "90-98%"],
              ["RAKE", "Varies projected max of 5%"],
              ["GAME FUND", "40%"],
              ["  - 33% Current Major Event Pool", ""],
              ["  - 50% Development Fund", ""],
              ["  - 17% Grand Final Pool", ""],
              ["Minimum Jackpot", "9%"],
              ["Major Jackpot", "10%"],
              ["Max Jackpot", "11%"],
              ["$LC Buy and Burn", "30%"],
              ["TOTAL:", "$VARIABLE"],
            ]}
          />
        </>

        <>
          <div class="h-[2rem]"></div>
          <p class="head-text">Race Types</p>
          <div class="h-[2rem]"></div>
          <p class="base-text">
            The below race types will be available in FBike at launch. All will
            be 6 gate events apart from “Showdown” racing. Further race types
            may be added in the future. The return structure below is what is
            distributed after variable rake is taken.
          </p>
          <ul className="list-disc list pl-[3rem]">
            {[
              "Podium (T3)",
              "Showdown (1v1)",
              "Gangs (T2)",
              "Trophy Racing (WTA)",
              "Doubling (DU)",
              "Centered (CNTR)",
              "The Grinder (GRNDR)",
            ].map((d) => {
              return <li className="base-text">{d}</li>;
            })}
          </ul>

          <div className="h-[2rem]"></div>
          <p className="mid-head-text">Finishing Position Payments</p>
          <Array2D_Table
            ar={[
              ["FINISHING POS / RACE TYPE", "1", "2", "3", "4", "5", "6"],
              ["T3", "65%", "25%", "10%", "--", "--", "--"],
              ["1v1", "100%", "--", "--", "--", "--", "--"],
              ["T2", "70%", "30%", "--", "--", "--", "--"],
              ["WTA", "100%", "--", "--", "--", "--", "--"],
              ["DU", "33%", "33%", "33%", "--", "--", "--"],
              ["CNTR", "--", "60%", "20%", "20%", "--", "--"],
              ["GRNDR", "25%", "25%", "25%", "25%", "--", "--"],
            ]}
          />
          <div className="h-[1rem]"></div>
          <p className="mid-head-text">Race Availability</p>
          <Array2D_Table
            ar={[
              ["CLASS / RACE TYPE", "1", "2", "3", "4", "5"],
              ["T3", "✅", "✅", "✅", "✅", "✅"],
              ["1v1", "✅", "✅", "✅", "✅", "--"],
              ["T2", "✅", "✅", "✅", "--", "--"],
              ["WTA", "✅", "✅", "✅", "✅", "--"],
              ["DU", "✅", "✅", "✅", "✅", "✅"],
              ["CNTR", "✅", "✅", "--", "--", "--"],
              ["GRNDR", "✅", "--", "--", "--", "--"],
            ]}
          />
        </>
        <>
          <div className="h-[2rem]"></div>
          <p className="head-text">$GOD NFT</p>
          <div className="page-center-image w-[20rem] m-2">
            <img src="https://imgur.com/lYds0vd.png" />
          </div>

          <div className="h-[2rem]"></div>
          <p className="head-text">$LC Token</p>
          <div className="page-center-image w-[20rem] m-2">
            <img src="https://imgur.com/YjaFouL.png" />
          </div>
        </>

        <>
          <div class="h-[2rem]"></div>
          <p class="head-text">Racing</p>
          <div class="h-[2rem]"></div>
          <p class="mid-head-text">Stars</p>
          <p class="base-text">
            Blue is the highest win count in the race sims
          </p>
          <p class="base-text">
            Yellow is the highest podium rate in the race sims
          </p>
          <div class="h-[2rem]"></div>
          <p class="mid-head-text">$DEZ Entry Rewards</p>
          <p class="base-text">
            Entering certain races will earn $DEZ rewards. This will change with
            frequency and will be controlled by a “live ops” function to ensure
            racing entries are moving.
          </p>
        </>

        <>
          <div class="h-[2rem]"></div>
          <p class="head-text">DNA Splicing</p>
          <p class="base-text">
            There are DNA Splicing Labs available for both male and female DNA
            Racings. DNA splicing allows for DNA Racing procreation. Female DNA
            Racings get 1 breed per cycle and Male DNA Racings have 3. Each can
            have one cycle per season.
          </p>
          <Array2D_Table
            ar={[
              ["GENERATION", "BREEDS"],
              ["Genesis", "UNLIMITED"],
              ["Morphed", "21"],
              ["Freak", "12"],
              ["X Class", "3"],
            ]}
          />

          <div class="h-[2rem]"></div>
          <p class="mid-head-text">Splicing Costs</p>
          <p class="base-text">
            All costs for splicing are in $USD and each splice incurs an
            additional USD $5 fee paid in $DEZ. They are as follows: THESE are
            preliminary will
          </p>
          <p class="base-text">
            70% of the $USD breed cost go to the GAME FUND
          </p>
          <p class="base-text">
            30% of the $USD breed cost is used to buy and burn $LC
          </p>
          <p class="base-text">
            100% of the $DEZ breed cost goes to the GAME FUND
          </p>

          <div class="h-[2rem]"></div>
          <p class="mid-head-text">Breeding Chart</p>
          <Array2D_Table
            ar={[
              ["GENESIS", "MORPHED", "FREAK", "X CLASS", "GENESIS"],
              ["MORPHED", "FREAK", "FREAK", "X CLASS", "MORPHED"],
              ["FREAK", "FREAK", "X CLASS", "X CLASS", "FREAK"],
              ["FREAK", "X CLASS", "X CLASS", "X CLASS", "X CLASS"],
              ["X CLASS", "X CLASS", "X CLASS", "X CLASS", "X CLASS"],
            ]}
          />
        </>

        <>
          <div class="h-[2rem]"></div>
          <p class="head-text">Token/NFT Types and Allocations</p>
          <div class="h-[2rem]"></div>
          <p class="mid-head-text">DEZ ($DEZ token)</p>
          <div className="page-center-image w-[20rem] m-2">
            <img src="https://imgur.com/g6YSf8Z.png" />
          </div>
          <div class="h-[2rem]"></div>
          <p class="base-text">There are 1 billion $DEZ tokens</p>
          <p class="base-text">
            DEZ Token can be thought of as the fuel that underpins the
            ecosystem. It can be earned or received in several ways.
          </p>

          <div className="h-[1rem]"></div>
          <p className="mid-head-text">Faucets for $DEZ:</p>
          <Array1D_List
            ar={[
              "Entering paid races early.",
              "Participating in the liquidity pool.",
              "Winning $DEZ denominated or juiced token races.",
              "Burn created DNA Racings for $DEZ.",
              "Own a $GOD token.",
              "Own a Genesis DNA Racing.",
            ]}
          />

          <div className="h-[1rem]"></div>
          <p className="mid-head-text">Sinks for $DEZ</p>
          <Array1D_List ar={["Stake for $LC rewards", "Breeding"]} />

          <div className="h-[1rem]"></div>
          <p className="mid-head-text">Future Concepts</p>
          <Array1D_List
            ar={[
              "Leasing",
              "Wagering",
              "Skins and boosts",
              "Player Advertising",
              "Others TBC",
            ]}
          />

          <div className="h-[1rem]"></div>
          <p className="mid-head-text">$DEZ Allocations</p>
          <Array1D_List
            ar={[
              `Genesis DNA Racing owners.	      20% Vested`,
              `GAME FUND.                       20% Initial + 35% Vested`,
              `$GOD token holders.              5% Vested`,
              `Founding Team.                   15% Vested`,
              `Consultants / Early Contributors	5% Vested`,
            ]}
          />

          <div className="h-[1rem]"></div>
          <p className="base-text">
            All $DEZ tokens vest over 36 months with a 12 month cliff. The only
            exclusion to this is the initial 20% pool which will be issued to
            the GAME FUND at inception.
          </p>
          <Array2D_Table
            ar={[
              ["$DEZ", "ALLOCATION"],
              ["GENESIS DNA Racing OWNERS", "200,000,000"],
              ["GAME FUND", "550,000,000"],
              ["$GOD NFT HOLDERS", "50,000,000"],
              ["FOUNDING TEAM", "150,000,000"],
              ["CONSULTANTS / EARLY CONTRIBUTORS", "50,000,000"],
            ]}
          />
        </>

        <>
          <div class="h-[2rem]"></div>
          <p class="head-text">Committee Members ($GOD NFT’s)</p>
          <div class="h-[2rem]"></div>
          <p class="base-text">
            The governance and control of the ecosystem is split into 110 $GOD
            NFT’s. These token holders vote on the direction of the ecosystem,
            as well as have dividends from jackpots through the payment of $LC.
          </p>
          <Array2D_Table
            ar={[
              ["$GOD", "ALLOCATION"],
              ["PUBLIC SALE", "45"],
              ["GAME FUND", "30"],
              ["$DEZ TOKEN STAKERS", "5"],
              ["FOUNDING TEAM", "25"],
              ["CONSULTANTS / EARLY CONTRIBUTORS", "5"],
            ]}
          />
          <div class="h-[2rem]"></div>
          <p class="base-text">
            Each token represents one vote inside a DAO excluding the 35 $GOD
            tokens reserved for $DEZ holders and GAME FUND.
          </p>
          <div class="h-[2rem]"></div>
          <p class="base-text">
            25% of each jackpot will be used to buy $LC tokens which are then
            distributed to $GOD token holders.
          </p>
        </>

        <>
          <div class="h-[2rem]"></div>
          <p class="head-text">Light Cycle ($LC Token)</p>
          <div class="h-[2rem]"></div>
          <p class="base-text">
            The ecosystem has its own bonding curve, into which any entity can
            buy and sell $LC tokens.
          </p>
          <p class="base-text">
            After each jackpot, the game buys $LC from the curve and distributes
            these tokens to Genesis horse owners and $GOD token holders.
          </p>
          <div class="h-[2rem]"></div>
          <p class="base-text">
            30% of all breeding fee’s and 30% race rake also go to buy $LC which
            the game burns.
          </p>
          <div class="h-[2rem]"></div>
          <p class="base-text">[INSERT BONDING CURVE AND SCHEDULE]</p>
        </>

        <>
          <div class="h-[2rem]"></div>
          <p class="head-text">The GAME FUND</p>
          <div class="h-[2rem]"></div>
          <p class="base-text">
            The GAME FUND funds the ongoing development, marketing, and
            management of the game and all liquidity and prize pools.
          </p>
          <div class="h-[2rem]"></div>
          <p class="mid-base-text">
            The GAME FUND makes its cash inflows from the following sources:
          </p>
          <Array1D_List
            ar={[
              "40% of rake on all race entries in the game.",
              "70% of breeding fee’s.",
              "5% marketplace fee’s for sale of DNA Racings.",
              "5% Exit Tribute from $LC",
              "The GAME FUND will also be issued 55% of all $DEZ tokens as outlined in the token vesting schedule to use in marketing, incentivising and growing the game.",
            ]}
          />

          <div class="h-[2rem]"></div>
          <p class="mid-base-text">GAME FUND dispersals:</p>
          <Array1D_List
            ar={[
              "GAME FUND dispersals are decided on quarterly and changes may be voted on by the committee.",
            ]}
          />
        </>

        <div className="h-[2rem]"></div>
        <div className="h-[2rem]"></div>
        <div className="h-[2rem]"></div>
      </div>

      <div className="home-footer"></div>
    </>
  );
}

export default MoreInfo;
