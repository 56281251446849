import React, { useMemo } from "react";
import { useQueries } from "react-query";
import { q_funds, qissuccesss } from "../queries/queries.js";
import { dec, getv, iso, nano } from "../utils/utils.js";
import { Loader01c } from "../components/anims.js";
import { Array2D_Table, Card } from "../components/utilityComps.js";
import _ from "lodash";
import { Helmet } from "react-helmet-async";

const wrap_cost = (cost) => "⟠ " + dec(cost, 6);
const wrap_per = (p) => (
  <span className="text-yellow-300">{dec(p * 100, 1) + "%"}</span>
);
const keys_ar_p = (ar, keys) =>
  keys.map((k) => (
    <div className="w-full text-center">
      {wrap_per(getv(ar, `${k}.p`))}
      {}
    </div>
  ));
const keys_ar_v = (ar, keys) =>
  keys.map((k) => (
    <div className="w-full text-center">{wrap_cost(getv(ar, `${k}.v`))}</div>
  ));

const keys_ar_n = (ar, keys) =>
  keys.map((k) => (
    <div className="w-full text-center">{wrap_cost(getv(ar, `${k}`))}</div>
  ));

const WrappTable = ({ children }) => {
  return <Card className={"max-w-[96vw] overflow-auto"}>{children}</Card>;
};

function Funds() {
  const [qofunds] = useQueries([q_funds()]);
  const funds = useMemo(() => {
    if (qissuccesss(qofunds)) return getv(qofunds, "data.result");
    return null;
  }, [qofunds.dataUpdatedAt]);

  const khmap = {
    racing: {
      head: "Racing",
      keys: [
        "racing.game_fund",
        "racing.min_jackpot",
        "racing.max_jackpot",
        "racing.major_jackpot",
        "racing.lc_bonding_curve",
      ],
      heads: [
        "GAME FUND",
        "MIN JACKPOT",
        "MAX JACKPOT",
        "MAJOR JACKPOT",
        "$LC BONDING CURVE",
      ],
    },

    racing_game_fund: {
      head: "Racing Game Funds",
      keys: [
        "racing.current_major_pot",
        "racing.dev_and_tech",
        "racing.grand_final_pool",
      ],
      heads: ["CURRENT MAJOR POT", "DEV AND TECH", "GRAND FINAL POOL"],
    },

    breeding: {
      head: "Breeding",
      keys: ["breeding.game_fund", "breeding.lc_bonding_curve"],
      heads: ["GAME FUND", "$LC BONDING CURVE"],
    },

    breeding_game_fund: {
      head: "Breeding Game Funds",
      keys: [
        "breeding.current_major_pot",
        "breeding.dev_and_tech",
        "breeding.grand_final_pool",
      ],
      heads: ["CURRENT MAJOR POT", "DEV AND TECH", "GRAND FINAL POOL"],
    },
  };

  return (
    <div className="mx-auto  max-w-[98vw] overflow-auto">
      <Helmet>
        <title>Funds || DNA Racing</title>
      </Helmet>
      <div className="">
        <div className="h-[2rem]"></div>
        {qofunds.isLoading && <Loader01c />}
        {qissuccesss(qofunds) && (
          <>
            <Card>
              <div className="grid md:grid-cols-2 xs:grid-cols-1">
                <div className="fc-cc">
                  <span className="text-center mid-head-text">
                    Racing Earnings
                  </span>
                  <span className="text-center mid-head-text">
                    {wrap_cost(funds.racing.rfe)}
                  </span>
                </div>
                <div className="fc-cc">
                  <span className="text-center mid-head-text">
                    Breeding Earnings
                  </span>
                  <span className="text-center mid-head-text">
                    {wrap_cost(funds.breeding.bfe)}
                  </span>
                </div>
              </div>
            </Card>
            <>
              <p className="head-text text-center">{khmap.racing.head}</p>
              <WrappTable>
                <Array2D_Table
                  ar={[
                    khmap.racing.heads,
                    keys_ar_p(funds, khmap.racing.keys),
                    keys_ar_v(funds, khmap.racing.keys),
                    ,
                  ]}
                />
              </WrappTable>
              <div className="h-[2rem]"></div>
            </>
            <>
              <p className="head-text text-center">
                {khmap.racing_game_fund.head}
              </p>
              <WrappTable>
                <Array2D_Table
                  ar={[
                    khmap.racing_game_fund.heads,
                    keys_ar_p(funds, khmap.racing_game_fund.keys),
                    keys_ar_v(funds, khmap.racing_game_fund.keys),
                    ,
                  ]}
                />
              </WrappTable>
              <div className="h-[2rem]"></div>
            </>

            <>
              <p className="head-text text-center">{khmap.breeding.head}</p>
              <WrappTable>
                <Array2D_Table
                  ar={[
                    khmap.breeding.heads,
                    keys_ar_p(funds, khmap.breeding.keys),
                    keys_ar_v(funds, khmap.breeding.keys),
                    ,
                  ]}
                />
              </WrappTable>
              <div className="h-[2rem]"></div>
            </>
            <>
              <p className="head-text text-center">
                {khmap.breeding_game_fund.head}
              </p>
              <WrappTable>
                <Array2D_Table
                  ar={[
                    khmap.breeding_game_fund.heads,
                    keys_ar_p(funds, khmap.breeding_game_fund.keys),
                    keys_ar_v(funds, khmap.breeding_game_fund.keys),
                    ,
                  ]}
                />
              </WrappTable>

              <div className="h-[2rem]"></div>
            </>

            <>
              <p className="head-text text-center">{"Jackpot"}</p>
              <WrappTable>
                <Array2D_Table
                  ar={[
                    ["Type", "", "PLAYER", "GENESIS", "$GOD"],

                    [
                      "",
                      "",
                      ..._.values(["player", "genesis", "god"]).map((k) =>
                        wrap_per(funds.pot_p[k]),
                      ),
                    ],

                    ...["minorpot", "majorpot", "maxpot"].map((j) => [
                      _.upperCase(j),
                      ..._.values(["tot", "player", "genesis", "god"]).map(
                        (k) => wrap_cost(funds.pots[j][k]),
                      ),
                    ]),
                  ]}
                />
              </WrappTable>

              <div className="h-[2rem]"></div>
            </>
          </>
        )}
      </div>
    </div>
  );
}

export default Funds;
