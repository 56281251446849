import {
  faChevronRight,
  faClose,
  faQuestion,
  faQuestionCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Array2D_Tablecn,
  BikeCard,
  Card,
  Img,
  InpText,
  MiniStatCard,
  Tag,
  TokenIcon,
} from "../components/utilityComps";
import {
  metamaskimg,
  polychainimg,
  polyimg,
  skinboximg,
  wethimg,
} from "../utils/links";
import { useMetaContext } from "../wrappers/MetaMaskWrapper.js";
import { Loader01c } from "../components/anims.js";
import { PopUp, PopupCloseBtn } from "../components/popup";
import { twMerge } from "tailwind-merge";
import { useQueries } from "react-query";
import {
  q_lootbox_base,
  q_lootbox_buy_box,
  q_lootbox_validate_purchase,
  q_lootbox_boxes,
  qiserr,
  qissuccess,
  qissuccesss,
  q_skinlootbox_v1_base,
  q_skinlootbox_v1_txns,
  polytxnlink,
  q_corelootbox_v2_lootnfts,
  polymaintxnlink,
  q_skinlootbox_v1_info,
  q_que_txn,
} from "../queries/queries.js";
import {
  cdelay,
  dec,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jstr,
  nano,
  nils,
  to_time_mini,
  toeth,
  tofeth,
} from "../utils/utils.js";
import { transfer_tokens } from "../queries/chainreq.js";
import _, { over } from "lodash";
import { Input } from "../components/input.js";
import WETH_MockToken from "../contracts/WETH_MockToken/WETH_MockTokenContract.js";
import { ethers } from "ethers";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import SkinLootbox_v1 from "../contracts/SkinLootbox_v1/SkinLootbox_v1_Contract.js";
import { SkinCard } from "./Skins.js";
import { Helmet } from "react-helmet-async";
import SkinLootbox_v1_101 from "../contracts/SkinLootbox_v1_101/SkinLootbox_v1_101_Contract.js";
import { useAppContext, tokdecn } from "../App.js";
import {
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../contracts/contract_funcs.js";
import { contractAddress_list } from "../contracts/contracts.js";
import { useLayoutContext } from "../components/Layout";
import { useThirdWebLoginContext } from "./ThirdWebLogin";
import { polygon } from "thirdweb/chains";

const MintContext = createContext({});
export const useMintContext = () => useContext(MintContext);

const countdown = "2023-09-19T23:00:00Z";

const raritychance = [
  ["Common", `${dec(0.625 * 100, 2)}%`],
  ["Uncommon", `${dec(0.22 * 100, 2)}%`],
  ["Rare", `${dec(0.1 * 100, 2)}%`],
  ["Epic", `${dec(0.03 * 100, 2)}%`],
  ["Legendary", `${dec(0.015 * 100, 2)}%`],
  ["Ultimate", `${dec(0.01 * 100, 2)}%`],
];

const boxestypes = _.chain([
  [
    "base",
    SkinLootbox_v1,
    {},
    "Base",
    "bg-purple-500",
    "#924CDA40",
    "https://i.imgur.com/0qcVE6t.png",
    "https://e0.pxfuel.com/wallpapers/28/614/desktop-wallpaper-tron-movie.jpg",
    <div className="fc-cc w-full flex-wrap">
      <div className="h-[2rem]"></div>
      <div className="resp-text-2 font-digi">Buy A Skin Mint Box </div>

      <div className="p-2">
        <p className="mid-head-text">
          <span className="resp-text-1">Rarity Chance</span>
        </p>
        <table className=" border-b border-slate-500 xs:w-[12rem] md:min-w-[20rem] resp-my-2 resp-text--1">
          <thead>
            <tr>
              <th className="border-b border-acc0/40 text-acc0 resp-px-2 md:pl-6">
                Skin Type
              </th>
              <th className="border-b border-acc0/40 text-acc0 resp-px-2 md:pl-6">
                Probability
              </th>
            </tr>
          </thead>
          <tbody>
            {raritychance.map((r) => {
              return (
                <tr>
                  {r.map((d) => (
                    <td className="border-b border-slate-600 resp-px-2 md:pl-6 py-1 text-left xs:min-w-[2rem] md:min-w-[5rem] resp-text--1">
                      {d}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>,
    "skinlootbox",
  ],
  [
    "bredmadness202403",
    SkinLootbox_v1,
    {
      contractAddress: contractAddress_list.skinlootbox_bredmadness202403,
    },
    "Bred Madness 24'03",
    "bg-red-500",
    "#924CDA40",
    "https://png.pngtree.com/png-vector/20230728/ourmid/pngtree-crate-clipart-vector-png-image_6807723.png",
    "https://dna-run-public.s3.us-east-2.amazonaws.com/random/portal_bg_madness_skins.png",
    <div className="fc-cc w-full flex-wrap">
      {/*
      <div className="h-[5rem]"></div>
      <div className="text-center resp-text-0 font-digi">
        Breed Madness Skins
      </div>

      <div className="p-2">
        <div className="grid grid-cols-2 overflow-visible">
          {[
            [
              "exclusive",
              "MMBLUE",
              "#FFFFFF",
              "https://i.imgur.com/xAhQ6ZY.png",
            ],
            null,
            null,
            [
              "exclusive",
              "MMRED",
              "#FFFFFF",
              "https://i.imgur.com/h1Hr5Qc.png",
            ],
          ].map((e) => {
            if (nils(e)) {
              return <div class="cols-span-1"></div>;
            }
            return (
              <div className="col-span-1 overflow-visible">
                <div class="scale-[1.35]">
                  <Img img={e[3]} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      */}
    </div>,
    "skinlootbox_bredmadness202403",
  ],
  // [
  //   "101",
  //   SkinLootbox_v1_101,
  //   "Halloween",
  //   "bg-orange-500",
  //   "#FFD58040",
  //   "https://static.vecteezy.com/system/resources/previews/009/597/575/original/halloween-pumpkin-ghost-png.png",
  //   "https://as2.ftcdn.net/v2/jpg/02/84/01/61/1000_F_284016119_3mtHYA9d642XVZl5XWwuvFcaAXu4KiqE.jpg",
  //   <div className="fc-cc w-full flex-wrap">
  //     <div className="h-[2rem]"></div>
  //     <div className="resp-text-2 font-digi">Halloween Skin Box </div>

  //     <div className="p-2">
  //       <p className="mid-head-text">
  //         <span className="resp-text-1">
  //           You will get 1 of 6 special halloween skins
  //         </span>
  //       </p>
  //       {/*  <table className=" border-b border-slate-500 xs:w-[12rem] md:min-w-[20rem] resp-my-2 resp-text--1">
  //         <thead>
  //           <tr>
  //             <th className="border-b border-acc0/40 text-acc0 resp-px-2 md:pl-6">
  //               Skin
  //             </th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {[
  //             ["rare", "Green Skull", "#42906F"],
  //             ["rare", "Grimm", "#FFFFFF"],
  //             ["rare", "Halloween", "#FF925D"],
  //             ["rare", "Halloween Zombie", "#FF925D"],
  //             ["rare", "Jack-O", "#FD6714"],
  //             ["rare", "Spider Web", "#FF925D"],
  //           ].map((r) => {
  //             return (
  //               <tr>
  //                 <td className="border-b border-slate-600 resp-px-2 md:pl-6 py-1 text-left xs:min-w-[2rem] md:min-w-[5rem] resp-text--1">
  //                   {r[1]}
  //                 </td>
  //               </tr>
  //             );
  //           })}
  //         </tbody>
  //       </table> */}
  //       <div className="grid grid-cols-2">
  //         {[
  //           ["rare", "Green Skull", "#42906F"],
  //           ["rare", "Grimm", "#FFFFFF"],
  //           ["rare", "Halloween", "#FF925D"],
  //           ["rare", "Halloween Zombie", "#FF925D"],
  //           ["rare", "Jack-O", "#FD6714"],
  //           ["rare", "Spider Web", "#FF925D"],
  //         ].map((e) => {
  //           return (
  //             <SkinCard {...{ skin: e[1], rarity: e[0], viewdets: true }} />
  //           );
  //         })}
  //       </div>
  //     </div>
  //   </div>,
  // ],
])
  .map((e) => {
    let i = 0;
    return {
      id: e[i++],
      Contract: e[i++],
      contractparams: e[i++],
      name: e[i++],
      color: e[i++],
      hexcode: e[i++],
      img: e[i++],
      banner: e[i++],
      InfoComp: e[i++],
      con_key: e[i++],
    };
  })
  .keyBy("id")
  .value();
const boxestypes_ord = [
  "base",
  // "bredmadness202403"
];

const Buy = ({ base, override = {} }) => {
  const mcon = useMintContext();
  const { selboxtype } = mcon;
  const boxtheme = boxestypes[selboxtype];

  const mmcon = useMetaContext();
  const { aumode, mmaccount: vault, auth } = mmcon;
  const { thirdweb_client, active_account } = useThirdWebLoginContext();

  const { qotxns } = useMintContext();

  const on_buyclick = async () => {};

  const [popup, set_popup] = useState(false);
  const [qty, set_qty] = useState(1);
  const [err, set_err] = useState(null);
  const [msg, set_msg] = useState(null);

  // useEffect(() => {
  //   try {
  //     let v = qty;
  //     if (v > base.available) throw new Error("not enough boxes available");
  //     v = parseInt(v);
  //     if (nils(v)) throw new Error("enter valid quantity");
  //     if (!_.inRange(v, 1, 25.001))
  //       throw new Error("min:1 max:25 boxes can be bought at a time");
  //     else set_err(null);
  //   } catch (err) {
  //     set_err(err.message);
  //   }
  // }, [qty]);

  const price1 = !nils(override?.price) ? override?.price : base?.price;
  const token = !nils(override?.token) ? override?.token : base?.token;

  const [loading, set_loading] = useState(false);
  const clickpayment = async () => {
    try {
      set_loading(true);

      const Contract = boxtheme.Contract;
      const contractparams = boxtheme.contractparams;

      const lootcon =
        aumode == "thirdweb"
          ? await Contract.get_contract({
              rpc: polygon.rpc,
              nosigner: true,
              ...contractparams,
              ...override.contractparams,
            })
          : await Contract.get_contract({
              ...contractparams,
              ...override.contractparams,
            });

      // const token = !nils(override.token) ? override.token : "weth";
      const paycon =
        aumode === "thirdweb"
          ? await t3_asset_signer(token == "DEZ" ? "dez" : "weth")
          : await mm_asset_signer(token == "DEZ" ? "dez" : "weth");
      console.log(vault, paycon);

      const topay = parseFloat(dec(price1 * qty * 1.01, tokdecn(token)));
      let balance = await paycon.balanceOf(vault);
      balance = parseFloat(tofeth(balance));
      if (balance < topay) throw new Error("not enough balance");

      try {
        const allowance = await paycon.allowance(
          vault,
          lootcon.contractAddress,
        );
        if (parseFloat(tofeth(allowance)) < parseFloat(topay)) {
          let approval = null;
          if (aumode === "thirdweb") {
            approval = await t3_contract_call(
              token,
              "approve",
              [lootcon.contractAddress, toeth(topay)],
              "txn",
              true,
              {
                active_account,
              },
            );
          } else {
            approval = await paycon.approve(
              lootcon.contractAddress,
              toeth(topay),
            );
            await approval.wait();
            await cdelay(1000);
          }
        }

        let resp = null;
        if (aumode === "thirdweb") {
          resp = await t3_contract_call(
            lootcon.contractAddress,
            "buyLootbox",
            [qty],
            "txn",
            true,
            { use_abimethods_k: "skinlootbox", active_account },
          );
          await cdelay(2 * 1e3);
        } else {
          resp = await lootcon.buyLootbox(qty);
          await resp.wait();
        }

        await q_que_txn({
          hash: resp.hash,
          vault,
          service: "skinlootbox",
          type: "buy_skinlootbox",
        }).queryFn();

        // console.log(resp);
      } catch (err) {
        console.log(err);
        if (err.reason) throw new Error(`Metamask: ${err.reason}`);
        else throw new Error(`Err: ${err.message}`);
      }

      set_err(null);

      await cdelay(5000);

      /*   setTimeout(() => {
        upd_closed_n();
      }, 5 * 1e3); */

      setTimeout(() => {
        qotxns.refetch();
      }, 8 * 1e3);

      set_loading(false);
      set_popup(false);
    } catch (err) {
      console.log(err);
      set_err(err.message);
      set_loading(false);
    }
  };

  return (
    <>
      <Tag
        onClick={() => {
          set_popup(true);
        }}
        className={twMerge(
          !nils(override.color) ? override.color : boxtheme.color,
        )}
      >
        Buy Now
      </Tag>

      <PopUp openstate={popup} overlayclose={false}>
        <Card className={"bg-dark min-w-[30rem]"}>
          <div className="fr-cc"></div>
          {!nils(err) && (
            <p className="my-2 p-2 text-red-300 w-full rounded-md border border-red-300">
              {err}
            </p>
          )}
          {!nils(msg) && (
            <p className="my-2 p-2 text-acc0 w-full rounded-md border border-acc0">
              {msg}
            </p>
          )}
          <div className="fr-sc">
            <div className="flex-1"></div>
            <PopupCloseBtn closepopup={() => set_popup(false)} />
          </div>
          <div className="mx-auto w-max">
            <div className="fr-cc gap-2">
              <p>{"Select Quantity"}</p>
              <InpText
                id="inp_qty"
                contprops={{ className: "w-max bg-dark" }}
                inpprops={{ className: "w-[4rem]" }}
                def_val={qty}
                setter={() => {
                  if (loading) return;
                  let v = document.getElementById("inp_qty").value;
                  v = parseInt(v);
                  try {
                    if (v > base.available)
                      throw new Error("not enough boxes available");

                    if (nils(v)) throw new Error("enter valid quantity");
                    if (!_.inRange(v, 1, 25.001))
                      throw new Error(
                        "min:1 max:25 boxes can be bought at a time",
                      );
                    else {
                      set_qty(v);
                      set_err(null);
                    }
                  } catch (err) {
                    set_err(err.message);
                  }
                }}
              />
            </div>
            <hr className="my-2" />
            <table className={Array2D_Tablecn.table_cn}>
              <tbody>
                <tr className={Array2D_Tablecn.tr_cn}>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">Quantity</p>
                  </td>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">{qty} qty.</p>
                  </td>
                </tr>
                <tr className={Array2D_Tablecn.tr_cn}>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">LootBox Cost</p>
                  </td>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">
                      {dec(price1, token == "DEZ" ? 0 : 6)} {token}
                    </p>
                  </td>
                </tr>
                <tr className={Array2D_Tablecn.tr_cn}>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">Total</p>
                  </td>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">
                      {`${dec(price1 * qty, 6)} ${token}`}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="fr-cc my-2">
              {loading ? (
                <>
                  <Loader01c size="s" />
                  <Tag className="">
                    please confirm transaction on MetaMask...
                  </Tag>
                </>
              ) : (
                <Tag
                  onClick={() => {
                    clickpayment();
                  }}
                  className="fr-cc gap-2 bg-acc0/40 text-white transform -skew-x-12"
                >
                  <Img className={"w-[2rem]"} img={metamaskimg} />
                  <span>Pay Now</span>
                </Tag>
              )}
            </div>
          </div>
        </Card>
      </PopUp>
    </>
  );
};

const TopBuyBoxesBanner = () => {
  const history = useNavigate();
  const mcon = useMintContext();
  const { selboxtype, set_selboxtype, qobase } = mcon;

  const base = useMemo(() => {
    if (!qissuccesss(qobase)) return null;
    let b = getv(qobase, `data.result.${selboxtype}`);
    return b;
  }, [qobase.dataUpdatedAt, selboxtype]);

  const boxtheme = boxestypes[selboxtype];

  const mmcon = useMetaContext();
  const { open_loginpop } = useLayoutContext();

  const { enablebuy } = useMintContext();

  return (
    <div className="">
      <div className="grid sm:grid-cols-2 md:grid-cols-6 max-w-[98vw] w-full overflow-x-hidden overflow-y-hidden">
        <div className="sm:col-span-2 md:col-span-3 realtive w-full">
          <div className="my-4 grid grid-cols-2 transform translate-y-[2rem] z-[30] flex-wrap">
            {boxestypes_ord.map((key) => {
              let o = boxestypes[key];
              return (
                <div class="fr-cc">
                  <Tag
                    onClick={() => {
                      set_selboxtype(key);
                    }}
                    className={twMerge(
                      "w-max",
                      "xs:px-4 lg:px-4",
                      "resp-text-1 font-digi",
                      key == selboxtype ? o.color : "bg-transparent text-white",
                    )}
                  >
                    {o.name}
                  </Tag>
                </div>
              );
            })}
            <div class="fr-cc w-full">
              <Tag
                onClick={() => {
                  history("/skingame");
                }}
                className={twMerge(
                  "w-max mx-auto text-center",
                  "xs:px-4 lg:px-4",
                  "resp-text-1 font-digi transition duration-300",
                  "hover:bg-purple-500",
                )}
              >
                {"Skin Game"}
              </Tag>
            </div>

            {false && (
              <div class="fr-cc w-full">
                <Tag
                  onClick={() => {
                    history("/skin-xmas");
                  }}
                  className={twMerge(
                    "w-max mx-auto text-center",
                    "xs:px-4 lg:px-4",
                    "resp-text-1 font-digi transition duration-300",
                    "hover:bg-red-500",
                  )}
                >
                  {"X-Mas"}
                </Tag>
              </div>
            )}
          </div>

          <div className="fc-cc w-full flex-wrap relative">
            <div
              className={twMerge(
                "md:w-[15rem] md:h-[15rem]  md:top-[2rem] md:left-[2rem]",
                "xs:w-[8rem] xs:h-[12rem]  xs:top-[1rem] xs:left-[1rem]",
                "absolute translate-x-[50%] translate-y-[50%] z-[0] blur-3xl",
                boxtheme.color,
              )}
            ></div>
            <div
              style={{
                background: `linear-gradient(${boxtheme.hexcode} 0%, rgba(100, 100, 100, 0) 100%)`,
              }}
              className="mint-box-container xs:mt-[50px] md:mt-[133px]"
            >
              <div
                className={twMerge(
                  "md:top-[-5rem] md:h-[12rem]",
                  "xs:top-[-2rem] xs:h-[6rem]",
                  "img-obey-cont rotate-[0deg] absolute",
                )}
              >
                <img src={boxtheme.img} />
              </div>
              <div className="xs:h-[5rem] md:h-[7rem]"></div>
              <p className="resp-text-2 font-digi">Skin Mint</p>

              {selboxtype == "base" ? (
                <>
                  {qissuccesss(qobase) && (
                    <div className="grid grid-cols-2 resp-gap-1">
                      {[[2], [3]].map((v) => {
                        let opts = {
                          contractparams: {
                            contractAddress:
                              contractAddress_list[`skinlootboxv${v}`],
                          },
                        };
                        let b = base[v] ?? {};
                        return (
                          <div className="w-full fc-cc">
                            <span className="resp-text-1 font-digi">
                              {dec(b.price, b.token == "DEZ" ? 0 : 4)}
                            </span>
                            <span className="resp-text-1 font-digi">
                              {b.token}
                            </span>
                            {mmcon.auth === true && enablebuy && (
                              <Buy {...{ base: b, override: opts }} />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="fr-cc">
                    <div className=""></div>
                    {qobase.isLoading ? <Loader01c size="s" /> : null}
                    {qissuccesss(qobase) && (
                      <>
                        <div className="fc-cc gap-0 px-4 border-x-2 border-purple-500/30">
                          <p className="leading-4	text-white resp-text-2 font-mon font-bold ">
                            {dec(base.price, 4)}
                            <span className="resp-text-1"> {base.token}</span>
                          </p>
                          <p className="leading-4	resp-text-1 font-thin resp-my-2">
                            price
                          </p>
                        </div>
                      </>
                    )}
                    <div className=""></div>
                  </div>

                  {enablebuy ? (
                    <>
                      {mmcon.auth == true && qissuccesss(qobase) && (
                        <>
                          {base.available > 0 ? (
                            <>
                              <Buy {...{ base }} />
                            </>
                          ) : (
                            <Tag className="bg-red-400 tex-black rounded-md italic ">
                              Out of Stock!!!
                            </Tag>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="fc-cc p-2 font-digi text-[1.2rem]">
                        <p>Starts in</p>
                        <p className="">{from_time_mini(countdown, true)}</p>
                      </div>
                    </>
                  )}
                  <div className="fr-sc w-full">
                    <div className="flex-1"></div>
                    <p className="text-acc0 italic font-bold my-2 resp-text--1">
                      {base?.available} boxes left
                    </p>
                  </div>
                </>
              )}
              <div className="fr-sc">
                {mmcon.auth == false &&
                  (mmcon.loading ? (
                    <Tag className="bg-dark text-acc0 fr-cc gap-2 font-digi">
                      <Loader01c size="s" />
                      <span>loading...</span>
                    </Tag>
                  ) : (
                    <Tag
                      onClick={() => {
                        open_loginpop();
                      }}
                      className=" mt-4 mb-2 px-4 py-2 bg-purple-500/80 text-black fr-cc gap-2"
                    >
                      <Img
                        className={"xs:w-[0.9rem] md:w-[1.5rem]"}
                        img={metamaskimg}
                      />
                      {mmcon.loading ? (
                        <span>loading...</span>
                      ) : (
                        <span>Connect Wallet</span>
                      )}
                    </Tag>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="sm:col-span-2 md:col-span-3 p-4  w-full mx-auto">
          {boxtheme.InfoComp}
        </div>
      </div>
    </div>
  );
};

export const ClosedBoxes = ({ closed_n, img, boxtype, override = {} }) => {
  const mmcon = useMetaContext();
  const { aumode, mmaccount: vault } = mmcon;
  const tcon = useThirdWebLoginContext();
  const { thirdweb_client, active_account } = tcon;

  const mintcon = useMintContext();

  const [resp, set_resp] = useState({});
  const [popup, set_popup] = useState(false);
  const [loading, set_loading] = useState(false);

  const openbox = async () => {
    set_loading(true);

    const Contract = boxestypes[boxtype].Contract;
    const lootcon =
      aumode === "thirdweb"
        ? await Contract.get_contract({
            rpc: polygon.rpc,
            nosigner: true,
            ...override.contractparams,
          })
        : await Contract.get_contract(override.contractparams);
    let resp = null;
    if (aumode === "thirdweb") {
      resp = await t3_contract_call(
        lootcon.contractAddress,
        "openLootbox",
        [1],
        "txn",
        true,
        { use_abimethods_k: "skinlootbox", active_account },
      );
    } else {
      resp = await lootcon.openLootbox(1, {});
      resp = await resp.wait();
    }
    await q_que_txn({
      hash: resp.hash,
      vault,
      type: "open_skinlootbox",
      service: "skinlootbox",
    }).queryFn();

    console.log(resp);
    await cdelay(5000);
    await cdelay(5000);

    set_loading(false);
  };

  const boxtheme = boxestypes[boxtype];

  // useEffect(() => {
  //   console.log(resp);
  // }, [resp]);

  // if (closed_n == 0)
  //   return (
  //     <>
  //       <p className="text-acc0 resp-text-0">
  //         No Closed LootBoxes owned currently
  //       </p>
  //     </>
  //   );
  return (
    <div className=" rounded-md  fr-cc w-max">
      <Card className={twMerge("resp-p-4 pl-[2rem] overflow-hidden w-max")}>
        <div className="fr-cc">
          <div className="relative xs:w-[6rem] md:w-[12rem] xs:h-[6rem] md:h-[12rem] mx-auto ">
            <div
              className={twMerge(
                "absolute animate-fade-in xs:w-[6rem] md:w-[12rem] xs:h-[6rem] md:h-[12rem] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] z-[5] blur-3xl",
                boxtheme.color,
              )}
            ></div>
            <Img
              img={boxtheme.img}
              className={
                "absolute xs:w-[6rem] md:w-[12rem] xs:h-[6rem] md:h-[12rem] z-[10]"
              }
            />
          </div>
          <div className="p-4">
            <p className="text-acc0 font-digi resp-text-2">x {closed_n}</p>
          </div>
        </div>
        {closed_n > 0 ? (
          <div className="fr-sc my-4">
            <div className="flex-1"></div>
            {loading && <Loader01c size="s" />}
            <Tag
              onClick={() => {
                if (loading) return;
                openbox();
              }}
              className="bg-acc0 -skew-x-12 text-black font-digi"
            >
              Open 1 Box
            </Tag>
          </div>
        ) : (
          <div className="fr-sc my-4">
            <Tag className="opacity-5">-</Tag>
          </div>
        )}
        {!nils(resp.err) && (
          <div className="fr-sc my-4">
            <p className="text-red-400 resp-text--1">{!nils(resp.err)}</p>
          </div>
        )}
      </Card>
    </div>
  );
};

const OpenedNFT = ({ skin }) => {
  const hash = skin.tx_hash;
  const date = skin.date;

  const txrow = (
    <>
      {nils(hash) ? (
        <>
          <Loader01c size="s" />
          <span className="text-acc0 resp-text-0">Awaiting Mint...</span>
        </>
      ) : (
        <>
          <Link
            className="fr-sc gap-1"
            target="_blank"
            to={polymaintxnlink(hash)}
          >
            <Img
              className={twMerge(
                "xs:w-[1.2rem] xs:h-[1.2rem]",
                "md:w-[2rem] md:h-[2rem]",
              )}
              img={polychainimg}
            />
            <span className="text-purple-300 resp-text-0">
              {hash.slice(0, 5)}..
            </span>
          </Link>
          <span className="resp-text-0">Minted on {iso_format(date)}</span>
        </>
      )}
    </>
  );

  return (
    <>
      <div className="  rounded-md ">
        <div className="fr-cc gap-2 p-2 px-4 resp-text-0 xs:hidden md:flex">
          <span>TokenID: {skin.skinid ?? "--"}</span>
          <div className="flex-1"></div>
          {txrow}
        </div>

        <div className="fc-ss gap-2 p-2 px-4 resp-text-0 xs:flex md:hidden">
          <span className="resp-my-2">TokenID: {skin.skinid ?? "--"}</span>
          {txrow}
        </div>
        {!nils(skin.skin) ? (
          <>
            <SkinCard
              {...{
                skin: skin.skin,
                rarity: skin.rarity,
                viewdets: true,
              }}
            />
          </>
        ) : (
          <p className="text-red-300 text-center">Couldnt estimate Skin</p>
        )}
      </div>
    </>
  );
};

const TxRow = ({ tx }) => {
  const [nftspopup, set_nftspopup] = useState(false);

  let date =
    moment().diff(tx.date, "seconds") < 5 * 60
      ? moment(tx.date).fromNow()
      : iso_format(tx.date, "DD-MMM YY, h:mm:ss a");
  let hash = tx.id.split(":")[0];

  const td_amt = (
    <div className="fr-sc resp-gap-1 resp-text--2">
      <div className="flex-1"></div>
      <div className="xs:w-[1rem] lg:w-[2rem]">
        <TokenIcon token={tx.token ?? "WETH"} />
      </div>
      <span>{dec(tx.amt, 4)}</span>
    </div>
  );

  const td_polyimg = (
    <Tag redirect={polytxnlink(hash)} className="xs:w-[1.5rem] lg:w-[3rem]">
      <Img img={polychainimg} />
    </Tag>
  );

  const td_names = <div className="fc-ss resp-gap-1 resp-text--2"></div>;

  const td_type = (
    <span className="resp-text--2">
      {_.chain(tx.type).split("_").map(_.upperCase).join(" ").value()}
    </span>
  );

  const td_date = <span className="resp-text--2">{date}</span>;

  const qty = getv(tx, "connects.qty");
  const td_inout = (
    <>
      {tx.type == "mint_skinlootbox" && (
        <Tag className="bg-purple-500/20 text-white">{qty} boxes Minted</Tag>
      )}
      {tx.type == "mint_skinlootbox_free" && (
        <Tag className="bg-pink-500/20 text-white">
          {qty} boxes Minted [FREE]
        </Tag>
      )}
      {tx.type == "open_skinlootbox" && (
        <Tag className="bg-yellow-500/20 text-white">{qty} boxes Opened</Tag>
      )}
    </>
  );

  const ls = getv(tx, "connects_post.skins");

  const td_view = (
    <>
      {tx.type == "open_skinlootbox" && (
        <>
          <Tag
            onClick={() => {
              set_nftspopup(true);
            }}
            className="bg-acc0/40 transform -skew-x-12 text-white gap-1 fr-cc"
          >
            <span>View NFTs</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Tag>

          <PopUp
            wrapcn={twMerge("top-[5rem] left-[50%]")}
            innercn={twMerge(" translate-x-[-50%] translate-y-[0%]")}
            openstate={nftspopup}
            overlayclose={false}
          >
            <Card
              className={
                "xs:w-full card-basic-bg card-grad-border md:w-[50rem] max-w-[95rem] relative"
              }
            >
              <div className="fr-cc">
                <div className="flex-1"></div>
                <Tag
                  onClick={() => set_nftspopup(false)}
                  className={twMerge(
                    "fc-cc rounded-full bg-red-500 ",
                    "absolute md:right-[-1rem] md:top-[-1rem] md:w-[2rem] md:h-[2rem]",
                    "absolute xs:right-[-0.5rem] xs:top-[-0.5rem] xs:w-[1rem] xs:h-[1rem]",
                  )}
                >
                  <FontAwesomeIcon icon={faClose} />
                </Tag>
              </div>
              {(ls ?? []).map((skin, i) => {
                return <OpenedNFT {...{ key: i, skin }} />;
              })}
            </Card>
          </PopUp>
        </>
      )}
    </>
  );

  return (
    <>
      <tr className={"thintdrow xs:hidden md:block"}>
        <td className={""}>{td_polyimg}</td>
        <td className={""}>{td_type}</td>
        <td className={""}>{td_amt}</td>
        <td className={""}>{td_date}</td>
        <td className={""}>{td_inout}</td>
        <td className={""}>{td_view}</td>
      </tr>
      <tr
        className={"thintdrow xs:block md:hidden border-b border-transparent"}
      >
        <td className={""} colSpan={2}>
          {td_polyimg}
        </td>
        <td className={""} colSpan={2}>
          {td_amt}
        </td>
        <td className={""} colSpan={2}>
          {td_date}
        </td>
      </tr>
      <tr className={"thintdrow xs:block md:hidden border-b border-acc0"}>
        <td className="" colSpan={6}>
          <div className="fr-sc">
            {td_inout}
            {td_view}
          </div>
        </td>
      </tr>
    </>
  );
};

const Transactions = ({ txns }) => {
  return (
    <Card className={"mx-auto card-basic-bg xs:w-full md:w-max  overflow-auto"}>
      {_.isEmpty(txns) ? (
        <p className="resp-text--1">No Transactions Yet</p>
      ) : (
        <table className={twMerge(Array2D_Tablecn.table_cn, "w-full")}>
          <tbody>
            {(txns || []).map((tx) => {
              return <TxRow tx={tx} />;
            })}
          </tbody>
        </table>
      )}
    </Card>
  );
};

function SkinMintPage() {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const enablebuy = nils(countdown) || iso() > countdown;

  const mmcon = useMetaContext();
  const { mmaccount: vault, auth } = mmcon;

  const [qobase] = useQueries([
    q_skinlootbox_v1_info(
      {},
      {
        staleTime: 30 * 1e3,
        refetchInterval: 30 * 1e3,
      },
    ),
  ]);

  const [qotxns] = useQueries([
    q_skinlootbox_v1_txns(
      { vault },
      {
        staleTime: 10 * 1e3,
        refetchInterval: 10 * 1e3,
      },
    ),
  ]);
  const txns = useMemo(() => {
    if (qissuccesss(qotxns)) return getv(qotxns, "data.result");
    return [];
  }, [qotxns.dataUpdatedAt]);

  const [selboxtype, set_selboxtype] = useState(psearch.selboxtype ?? "base");
  useEffect(() => {
    if (selboxtype !== psearch.selboxtype) upd_psearch({ selboxtype });
  }, [selboxtype]);

  const mintcon = {
    vault,
    enablebuy,
    qotxns,
    txns,

    qobase,
    selboxtype,
    set_selboxtype,
  };

  const boxtheme = boxestypes[selboxtype];

  const pagetitle = useMemo(() => {
    if (nils(selboxtype) || selboxtype == "base")
      return "Skin Mint || DNA Racing";
    return `${boxtheme.name} || Skin Mint || DNA Racing`;
  }, [selboxtype]);

  return (
    <MintContext.Provider value={mintcon}>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="relative">
        <div className="xs:hidden md:block max-h-[calc(800px)] h-[calc(100vh-9rem)] ">
          <div className="">
            <div
              style={{ backgroundImage: `url(${boxtheme.banner})` }}
              className="hero-section"
            >
              <div className="gradient-overlay"></div>
              <div className="h-[3rem]"></div>
              <div className="html-overlay relative">
                <div className="home-container">
                  <TopBuyBoxesBanner />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xs:block md:hidden">
          <div className="home-container">
            <TopBuyBoxesBanner />
          </div>
        </div>
        {auth && (
          <div className="home-container">
            <div className="h-[2rem]"></div>
            <div className="h-[2rem]"></div>
            <OwnedBoxesList />
            <p className="text-acc0 italic  resp-text-1">Transactions</p>
            <Transactions {...{ txns }} />
          </div>
        )}
      </div>
    </MintContext.Provider>
  );
}

const OwnedBoxesList = () => {
  const mintcon = useMintContext();
  const { closed_n, txns, selboxtype } = mintcon;

  const base = useMemo(() => {
    return boxestypes[selboxtype];
  }, [jstr(selboxtype)]);

  return (
    <div className="">
      <div className="fr-sc my-2 gap-2">
        <p className="text-acc0 font-digi resp-text-2">LootBoxes</p>
      </div>
      <p className="text-acc0 italic  resp-text-1">Closed Boxes</p>
      <div className="fr-sc flex-wrap w-full resp-gap-2">
        {selboxtype == "base" ? (
          <>
            {[[2], [3]].map((v) => {
              let opts = {
                contractparams: {
                  contractAddress: contractAddress_list[`skinlootboxv${v}`],
                },
              };
              let key = "base";
              return (
                <ClosedBoxOfType
                  {...{
                    boxtype: key,
                    key: `${key}-${v}`,
                    override: opts,
                  }}
                />
              );
            })}
          </>
        ) : (
          <>
            <ClosedBoxOfType
              {...{
                boxtype: selboxtype,
                override: {
                  contractparams: base.contractparams,
                },
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

const ClosedBoxOfType = ({ boxtype, override = {} }) => {
  const { thirdweb_client, active_account } = useThirdWebLoginContext();
  const mmcon = useMetaContext();
  const { aumode, mmaccount: vault, auth } = mmcon;
  // useEffect(() => {
  //   console.log("cb", boxtype, override);
  // }, []);

  const [closed_n, set_closed_n] = useState(null);
  const upd_closed_n = async () => {
    if (!mmcon.auth) return null;
    let Contract = boxestypes[boxtype].Contract;
    // console.log(boxtype, override.contractparams);
    let lootcon =
      aumode == "thirdweb"
        ? await Contract.get_contract({
            rpc: polygon.rpc,
            nosigner: true,
            ...override.contractparams,
          })
        : await Contract.get_contract(override.contractparams);
    try {
      let bal = await lootcon.accountOwns(vault);
      bal = Number(bal);
      set_closed_n(bal);
    } catch (err) {}
  };
  useEffect(() => {
    const inter = setInterval(() => {
      upd_closed_n();
    }, 10 * 1e3);
    setTimeout(() => upd_closed_n(), 2 * 1e3);
    return () => {
      clearInterval(inter);
    };
  }, [mmcon.auth]);

  return (
    <div>
      <ClosedBoxes
        boxtype={boxtype}
        closed_n={closed_n}
        img={skinboximg}
        override={override}
      />
    </div>
  );
};

export default SkinMintPage;
