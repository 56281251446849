import { MetaMaskSDK } from "@metamask/sdk";
import detectEthereumProvider from "@metamask/detect-provider";
import { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { Tag } from "../components/utilityComps.js";
import { createContext } from "react";
import { useContext } from "react";
import { useAppContext } from "../App.js";
import _ from "lodash";
import { cdelay, getv, jstr, nils } from "../utils/utils.js";
import {
  q_auth_get_nonce,
  q_auth_verify_nonce,
  q_auth_verify_token,
  qiserr,
} from "../queries/queries.js";
import { PopUp } from "../components/popup.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueries } from "react-query";
import { useNavigate } from "react-router-dom";

const MetaMaskContext = createContext({});
export const useMetaContext = () => useContext(MetaMaskContext);

export const token_locpath = "jwt_auth_token";
export const tokenacc_locpath = "jwt_auth_account";
export const tokenaumode_locpath = "jwt_auth_aumode";
export const tokenauth_locpath = "jwt_auth_auth";

const get_token = () => {
  let token = localStorage.getItem(token_locpath);
  return token;
};

const gen_token = async () => {
  let mm = window.ethereum;
  let account = mm.selectedAddress;
  let vault = account.toLowerCase();

  let non = await q_auth_get_nonce({ vault });
  let nonce = getv(non, "result.nonce");
  if (nils(nonce)) throw new Error("couldn't fetch nonce");

  let tosign = nonce;
  let signed_data = await mm.request({
    method: "personal_sign",
    params: [tosign, account],
  });

  let resptok = await q_auth_verify_nonce({ signed_data, vault });
  if (resptok?.status == "error") throw new Error(resptok.err);

  let token = getv(resptok, "result.token");
  localStorage.setItem(token_locpath, token);
  localStorage.setItem(tokenacc_locpath, account);
  localStorage.setItem(tokenaumode_locpath, "wallet");
  localStorage.setItem(tokenauth_locpath, "true");
  return token;
};

const request_metamask_login = async () => {
  let mm = window.ethereum;
  let resp = await mm.request({ method: "eth_requestAccounts" });
  let acc = getv(resp, 0);
  return acc;
};

const relogin = async () => {
  let mm = window.ethereum;
  await mm.request({
    method: "wallet_requestPermissions",
    params: [{ eth_accounts: {} }],
  });
};

const verify_token = async () => {
  let token = localStorage.getItem("jwt_auth_token");
  let vault = window.ethereum.selectedAddress?.toLowerCase();
  let resp = await q_auth_verify_token({ vault, token });
  if (resp.err) throw new Error(resp.err);
  return resp.result;
};

export const MetaMaskWrapper = (props) => {
  const { path } = useAppContext();
  const history = useNavigate();

  const mm = window.ethereum;
  const [mm_connected, set_mm_connected] = useState(undefined);
  useEffect(() => {
    let fn = async () => {
      await cdelay(500);
      const mm = window.ethereum;
      let conn = nils(mm) ? false : window.ethereum?.isConnected();
      set_mm_connected(conn);
    };
    fn();
    setTimeout(fn, 2000);
    setTimeout(fn, 5000);
  }, []);

  const sel_correct_chain = async () => {
    try {
      const corr_chainId = 137;
      const corr_chainId_hex = "0x" + corr_chainId.toString(16);

      const mm = window.ethereum;
      if (nils(mm)) return;
      let chainIdHex = await mm.request({ method: "eth_chainId" });
      const chainId = parseInt(chainIdHex, 16);
      if (chainId !== corr_chainId) {
        await mm.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: corr_chainId_hex }],
        });
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (!window.ethereum) return;
    if (!mm_connected) return;

    window.ethereum.on("accountsChanged", () => {
      console.log("accountsChanged");
      set_refreshc(refreshc + 1);
    });
  }, [mm_connected]);

  // const auth = mm_connected && !nils(vault);

  const [refreshc, set_refreshc] = useState(0);
  const [aumode, account, token] = useMemo(() => {
    let aumode = localStorage.getItem(tokenaumode_locpath);

    if (aumode == "wallet") {
      if (!window.ethereum) return [null, null, null];
      const fn = () => {
        let account = window.ethereum.selectedAddress;
        if (!nils(account)) account = account.toLowerCase();
        let token = get_token();
        // console.log("refreshc", refreshc, account, token);
        return [aumode, account, token];
      };
      return fn();
    } else if (aumode == "thirdweb") {
      let account = localStorage.getItem(tokenacc_locpath);
      let token = localStorage.getItem(token_locpath);
      if (!token || !account) return [null, null, null];
      return [aumode, account, token];
    } else return [null, null, null];
  }, [refreshc]);

  const vault = account && account.toLowerCase();
  // console.log("useMetaMaskContext:vault", vault);

  const qo_enabled = !nils(vault) && !nils(token);
  const [qo_auth] = useQueries([
    {
      queryKey: ["auth", jstr({ vault, token })],
      queryFn: () => q_auth_verify_token({ vault, token }),
      staleTime: 5 * 60 * 1e3,
      enabled: qo_enabled,
    },
  ]);
  const [auth, autherr] = useMemo(() => {
    // console.log("qo_auth", qo_enabled, qo_auth);
    if (!qo_enabled) return [false, "couldnt find auth details"];

    let err = qiserr(qo_auth);

    if (err) {
      // history("/");
      localStorage.removeItem(token_locpath);
      localStorage.removeItem(tokenacc_locpath);
      localStorage.removeItem(tokenaumode_locpath);
      return [false, err];
    }

    let auth = getv(qo_auth, "data.result.auth");
    if (auth) {
      localStorage.setItem(tokenauth_locpath, "true");
      return [true, null];
    } else {
      localStorage.setItem(tokenauth_locpath, "false");
      return [false, `couldn't authenticate`];
    }
  }, [qo_auth.dataUpdatedAt]);

  useEffect(() => {
    if (auth == true) {
      sel_correct_chain();
    }
  }, [auth]);

  const auth_refresh = async () => {
    set_refreshc(refreshc + 1);
    await cdelay(500);
    await qo_auth.refetch();
  };

  const logout = async () => {
    localStorage.removeItem(token_locpath);
    localStorage.removeItem(tokenacc_locpath);
    localStorage.removeItem(tokenaumode_locpath);
    localStorage.removeItem(tokenauth_locpath);
    await auth_refresh();
  };

  const [logloading, set_logloading] = useState(false);
  const [logerr, set_logerr] = useState(null);

  const start_login = async () => {
    if (mm_connected == false) return;
    set_logloading(true);
    try {
      let account = await request_metamask_login();
      if (nils(account)) throw new Error("metamask window was closed abruptly");

      let jwtacc = localStorage.getItem(tokenacc_locpath);
      if (jwtacc == account) {
      } else {
        await mmcon.gen_token();
      }
      await cdelay(1000);
      await auth_refresh();
      await cdelay(2000);
      set_logloading(false);
    } catch (err) {
      set_logerr(err.message);
    }
  };

  const try_mm_connect = async () => {
    await cdelay(500);
    let conn = nils(window.ethereum) ? false : true;
    set_mm_connected(conn);
    return conn;
  };

  const mmcon = {
    mm,
    mm_connected,
    // login: request_metamask_login,
    login: start_login,
    request_metamask_login,
    gen_token,
    get_token,
    verify_token,
    relogin,

    account,
    vault,
    mmaccount: vault,

    auth,
    autherr,
    auth_refresh,
    logout,
    aumode,

    try_mm_connect,
  };
  // console.log("meta", mmcon);

  const bypass = useMemo(() => {
    return true;
  }, [path]);

  const show = bypass || mm_connected === true;

  return (
    <>
      <MetaMaskContext.Provider value={mmcon}>
        {show == true && <>{props.children}</>}
      </MetaMaskContext.Provider>
    </>
  );
};

export const InstallMM = () => {
  return (
    <div className="min-w-[30rem]">
      <p className="resp-text-1 text-center">Please Install MetaMask</p>
      <a
        target="_blank"
        href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
      >
        <div className="fr-cc bg-white rounded-md resp-p-2 resp-gap-2 w-max mx-auto">
          <img
            className="h-[4rem]"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png"
            alt=""
          />
          <img
            className="h-[4rem]"
            src="https://storage.googleapis.com/web-dev-uploads/image/WlD8wC6g8khYWPJUsQceQkhXSlv1/YT2Grfi9vEBa2wAPzhWa.png"
          />
        </div>
      </a>
    </div>
  );
};
