import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress_list } from "../contracts.js";
import { blocktime_iso, nils } from "../../utils/utils.js";

class Contract {
  constructor({ contractAddress, provider, signer, nosigner = false }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    if (nosigner === true)
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        provider,
      );
    else
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        signer,
      );
  }

  async setBikeCreditsAddress(bgcAddress) {
    await this.contract.setBikeCreditsAddress(bgcAddress);
  }
  async getBikeCreditsAddress(bgcAddress) {
    await this.contract.bgc_address(bgcAddress);
  }

  async setBikeContractAddress(bikeconAddress) {
    await this.contract.bikecon_address(bikeconAddress);
  }
  async getBikeContractAddress(bikeconAddress) {
    await this.contract.getBikeContractAddress(bikeconAddress);
  }

  parseBikeBurnDoc(a) {
    let i = 0;
    let o = {
      hid: Number(a[i++]),
      burned: a[i++],
      owner: a[i++],
      reward: a[i++],
      burned_at: blocktime_iso(Number(a[i++])),
    };
    return o;
  }

  async getBikeBurnDoc(hid) {
    let o = await this.contract.getBikeBurnDoc(hid);
    return this.parseBikeBurnDoc(o);
  }

  async requestBurn(hid) {
    let resp = await this.contract.requestBurn(hid);
    return resp;
  }

  async burnBike(hid, reward) {
    return await this.contract.burnBike(hid, reward);
  }

  async revokeRequest(hid) {
    return await this.contract.revokeRequest(hid);
  }

  async getParsedLogs(logs) {
    if (!logs) return [];

    let logrows = [];
    for (let log of logs) {
      let par = this.contract.interface.parseLog(log);

      if (nils(par)) continue;

      let name = par.name;
      let o = {
        name,
        logIndex: log.logIndex,
        hash: log.transactionHash,
        blockNumber: log.blockNumber,
      };
      o.id = `${o.hash}:${o.logIndex}`;

      if (name == "BikeBurnRequested") {
        // event BikeBurnRequested(uint hid, address reqby);
        let a = par.args;
        o.args = { hid: Number(a[0]), reqby: a[1] };
      } else if (name == "BikeBurnRevoked") {
        // event BikeBurnRevoked(uint hid, address reqby);
        let a = par.args;
        o.args = { hid: Number(a[0]), reqby: a[1] };
      } else if (name == "BikeBurned") {
        // event BikeBurned(Bike b);
        let a = par.args;
        let hid = Number(a[0].hid);
        let reqby = a[0].reqby;
        o.args = { hid, reqby, b: a[0] };
      }

      logrows.push(o);
    }
    return logrows;
  }
}

const k = "bikeburner";

const get_contract_address = () => {
  return contractAddress_list[k];
};

const get_contract = async (ext = {}) => {
  const contractAddress = get_contract_address();
  const provider = !nils(ext.rpc)
    ? new ethers.JsonRpcProvider(ext.rpc)
    : new ethers.BrowserProvider(window.ethereum);
  const signer = ext.nosigner === true ? null : await provider.getSigner();

  const pars = { contractAddress, provider, signer, ...ext };
  const runner = new Contract(pars);
  return runner;
};

export const BikeBurner = {
  k,
  class: Contract,
  get_contract: get_contract,
  get_contract_address,
};
