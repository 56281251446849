import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress_list } from "../contracts.js";
import { format_ether, nils } from "../../utils/utils.js";

const getgasinfo = async () => ({});

class SkinGameContract {
  constructor({ contractAddress, provider, signer, nosigner = false }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    if (nosigner === true)
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        provider,
      );
    else
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        signer,
      );
  }

  async setSkinContract(_skinCon, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setSkinContract(_skinCon, info);
    const resp = await tx.wait();
    return resp;
  }

  async setCreditsContract(_creditsCon, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setCreditsContract(_creditsCon, info);
    const resp = await tx.wait();
    return resp;
  }

  async setBoxContract(_boxCon, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setBoxContract(_boxCon, info);
    const resp = await tx.wait();
    return resp;
  }

  async setBoxTypeInfo(
    boxtype,
    rarity,
    skinburn_credit,
    boxtypebuy_price,
    extinfo = {},
  ) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setBoxTypeInfo(
      boxtype,
      rarity,
      skinburn_credit,
      boxtypebuy_price,
      info,
    );
    const resp = await tx.wait();
    return resp;
  }

  async getBoxTypeInfo(boxtype) {
    const resp = await this.contract.getBoxTypeInfo(boxtype);
    let [_boxtype, rarity, burn_credits, box_price] = resp;
    let o = {
      boxtype,
      rarity,
      burn_credits_wei: burn_credits.toString(),
      box_price_wei: box_price.toString(),
      burn_credits: format_ether(burn_credits),
      box_price: format_ether(box_price),
    };
    return o;
  }

  async ownsBoxes(address, boxtype, extinfo = {}) {
    let resp = await this.contract.ownsBoxes(address, boxtype, extinfo);
    resp = Number(resp);
    return resp;
  }

  async burnSkin(skinid, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.burnSkin(skinid, info);
    const resp = await tx.wait();
    return resp;
  }

  async buyBox(qty, boxtype, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.buyBox(qty, boxtype, info);
    const resp = await tx.wait();
    return resp;
  }

  async openBox(boxtype, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.openBox(boxtype, info);
    const resp = await tx.wait();
    return resp;
  }
}

const name = "skingame";
const get_contract_address = () => {
  let con = contractAddress_list[name];
  // console.log("get_contract_address", name, con);
  return con;
};

const get_SkinGameContract = async (ext = {}) => {
  const contractAddress = get_contract_address();
  const provider = !nils(ext.rpc)
    ? new ethers.JsonRpcProvider(ext.rpc)
    : new ethers.BrowserProvider(window.ethereum);
  const signer = ext.nosigner === true ? null : await provider.getSigner();
  const pars = { contractAddress, provider, signer, ...ext };
  const runner = new SkinGameContract(pars);
  return runner;
};

export const SkinGame = {
  class: SkinGameContract,
  get_contract: get_SkinGameContract,
};
