import React from "react";
import { Helmet } from "react-helmet-async";

function GameLoop() {
  return (
    <>
      <Helmet>
        <title>GameLoop || DNA Racing</title>
      </Helmet>
      <div className="home-container">
        <div className="h-[2rem]"></div>
        <p className="head-text">Racing & Seasons</p>
        <p className="base-text">
          The aim of the game is to win Major Tournaments and ultimately the
          Grand Final.
        </p>
        <p className="base-text">
          Each year is split into six seasons and each season is finalized by a
          Major Tournament. 12 compete for each Major Final.
        </p>
        <p className="base-text">
          To qualify for the Grand Final you must win a qualification event to
          win a “Ticket”. Tickets are standalone NFT’s which are tradable items.
          18 compete for the Grand Final.
        </p>
        <p></p>
        <div className="h-[2rem]"></div>

        <p className="mid-head-text">MUMBAI- March 2nd to April 30th</p>
        <p className="pre-base-text">{`      Major Tournament- THE PEACOCK SALVER`}</p>
        <div className="page-center-image w-[20rem] m-2">
          <img src="https://imgur.com/xpHwcIB.png" />
        </div>
        <div className="h-[2rem]"></div>

        <p className="mid-head-text">HONG KONG- May 1st to June 30th</p>
        <p className="pre-base-text">{`      Major Tournament- THE DRAGON SHIELD`}</p>
        <div className="page-center-image w-[20rem] m-2">
          <img src="https://imgur.com/M4Sjcog.png" />
        </div>
        <div className="h-[2rem]"></div>

        <p className="mid-head-text">NEW YORK- July 1st to August 31st</p>
        <p className="pre-base-text">{`      Major Tournament- THE LIBERTY ORB`}</p>
        <div className="page-center-image w-[20rem] m-2">
          <img src="https://imgur.com/84aZPpM.png" />
        </div>
        <div className="h-[2rem]"></div>

        <p className="mid-head-text">LONDON- September 1st to October 31st</p>
        <p className="pre-base-text">{`      Major Tournament- THE TOWER CUP`}</p>
        <div className="page-center-image w-[20rem] m-2">
          <img src="https://imgur.com/qf7b9nQ.png" />
        </div>
        <div className="h-[2rem]"></div>

        <p className="mid-head-text">PARIS- November 1st to December 31st</p>
        <p className="pre-base-text">{`      Major Tournament- THE ARC VESSEL`}</p>
        <div className="page-center-image w-[20rem] m-2">
          <img src="https://imgur.com/AkwnrTX.png" />
        </div>
        <div className="h-[2rem]"></div>

        <p className="mid-head-text">DUBAI- January 1st to February 28th</p>
        <p className="pre-base-text">{`      Major Tournament- THE BURJ MEDAL `}</p>
        <div className="page-center-image w-[20rem] m-2">
          <img src="https://imgur.com/T28HwIZ.png" />
        </div>
        <div className="h-[2rem]"></div>

        <p className="mid-head-text">GRAND FINAL- March 1 </p>
        <p className="pre-base-text">{`      THE KING’S CHALICE`}</p>
        <div className="page-center-image w-[20rem] m-2">
          <img src="https://imgur.com/5uaH0sv.png" />
        </div>
        <p className="pre-base-text">{`

Each Season is split into 8 weeks:

    -  Week 1- Season Maiden- (Qualifying event for 1x Grand Final Ticket)
    -  Week 2- Premier- (2 x Major Final Tickets)
    -  Week 3- Premier- (2 x Major Final Tickets)
    -  Week 4- Premier- (2 x Major Final Tickets)
    -  Week 5- Midseason Maiden 
    -  Week 6- Premier- (2 x Major Final Tickets)
    -  Week 7- Premier- (2 x Major Final Tickets)
    -  Week 8- Premier- (2 x Major Final Tickets)
Major Winner- (1 x Grand Final Ticket) 

The 6 winners of the premiers go through to the Major Final. 6 other finalists are chosen for challenges each week, which changes tournament to tournament and season to season.

Three Grand Final Tickets are available each season:
    -  1x Winning the “Season Maiden”
    -  1x Winning a Major Final
    -  1x Largest Jackpot Winner of the season
`}</p>
        <div className="h-[2rem]"></div>
        <div className="h-[2rem]"></div>
        <div className="h-[2rem]"></div>

        <p className="head-text">Tournaments</p>
        <ul className="base-text list-disc">
          <li>
            All tournaments, with the exception of “The Majors”, span over 2-5
            days and require participation in various qualifying races.
          </li>
          <li>
            “The Majors” must be qualified for throughout a season and are
            sit’n’go style automations.
          </li>
        </ul>
        <div className="h-[2rem]"></div>
        <div className="h-[2rem]"></div>
      </div>
      <div className="home-footer"></div>
    </>
  );
}

export default GameLoop;
