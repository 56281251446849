import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { tokdecn, tokdecn2, useAppContext } from "../App";
import {
  faChevronRight,
  faCircle,
  faClose,
  faLock,
  faSpinner,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useNowContext } from "../App";
import {
  Array2D_Tablecn,
  Card,
  Img,
  InpText,
  Tag,
  TokenIcon,
} from "../components/utilityComps";
import {
  polytxnlink,
  qissuccesss,
  q_que_txn,
  q_sheet_pages_chainplay,
  q_skins_direct_info,
  q_skins_direct_txns,
} from "../queries/queries";
import { tablecn } from "../utils/cn_map";
import { get_skin_transparent_img, polychainimg } from "../utils/links";
import {
  cdelay,
  dec,
  from_time_mini,
  getv,
  iso_format,
  jstr,
  nils,
  toeth,
  tofeth,
} from "../utils/utils";
import { useMetaContext } from "../wrappers/MetaMaskWrapper";
import { PopUp, PopupCloseBtn } from "../components/popup";
import { Loader01c } from "../components/anims";
import { SkinsDirect_v1 } from "../contracts/SkinsDirect/SkinsDirect_v1";
import { mm_asset_signer } from "../contracts/contract_funcs";
import moment from "moment";
import { useQueries } from "react-query";

const ChainPlayContext = createContext({});
export const useChainPlayContext = () => useContext(ChainPlayContext);

const page_w = "mx-auto w-[80rem] max-w-[98vw]";
const card_cn =
  "backdrop-blur-md bg-gradient-to-br from-orange-500/25 to-pink-500/25 text-white";
const highlight_txt = "font-digi text-orange-400";
const TableView = ({ head, d }) => {
  return (
    <div>
      {!nils(head) && (
        <p
          class={twMerge(
            highlight_txt,
            " resp-text-1 text-left font-digi mt-[1rem] mb-1",
          )}
        >
          {head}
        </p>
      )}
      <Card className={"card-dark-bg w-full rounded-xl"}>
        <table className={twMerge("thintdrowp4-table", "w-full")}>
          <thead>
            {(d || []).slice(0, 1).map((r) => {
              return (
                <tr className={highlight_txt}>
                  {r.map((e) => {
                    return <td class="text-left">{e}</td>;
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {(d || []).slice(1).map((r) => {
              return (
                <tr>
                  {r.map((e) => {
                    return <td class="text-left font-mon">{e}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </div>
  );
};

const boxestypes = _.chain([
  [
    "base",
    "Base",
    "bg-gradient-to-tr from-orange-500 to-pink-500",
    "#D8225C80",
    "https://png.pngtree.com/png-vector/20230814/ourmid/pngtree-sticker-with-a-pirate-chest-has-plastic-toys-in-it-clipart-vector-png-image_6872810.png",
    "https://e0.pxfuel.com/wallpapers/28/614/desktop-wallpaper-tron-movie.jpg",
  ],
])
  .map((e) => {
    let i = 0;
    return {
      id: e[i++],
      name: e[i++],
      color: e[i++],
      hexcode: e[i++],
      img: e[i++],
      banner: e[i++],
      InfoComp: e[i++],
    };
  })
  .keyBy("id")
  .value();

const boxtheme = boxestypes["base"];

const SkinBuyCard = ({
  skininfo,
  boxtheme,
  enablebuy = true,
  unlocks_at,
  on_buy_click = () => {},
}) => {
  const { now } = useNowContext();
  const mmcon = useMetaContext();
  const { vault } = mmcon;
  return (
    <div class="relative mx-auto xs:w-[15rem] lg:w-[25rem] res-text--1">
      <div className="fc-cc w-full flex-wrap relative">
        <div
          className={twMerge(
            "absolute aspect-[1/1] z-[0] blur-3xl",
            "top-[55%] left-[50%]",
            "translate-x-[-50%] translate-y-[-50%]",
            "md:w-[15rem] xs:w-[8rem]",
            boxtheme.color,
          )}
        ></div>
        <div
          style={{
            background: `linear-gradient(${boxtheme.hexcode} 0%, rgba(100, 100, 100, 0) 100%)`,
          }}
          className="mint-box-container"
        >
          <div
            className={twMerge(
              "img-obey-cont rotate-[10deg]",
              "mx-auto xs:w-[10rem] lg:w-[14rem] aspect-[1/1]",
              "absolute lg:top-[-5rem] xs:top-[-2rem]",
            )}
          >
            <img src={boxtheme.img} />
          </div>
          <div class="h-[7rem]"></div>
          <div class="fc-cc">
            <p class="font-digi resp-text-2"> Mint {skininfo.name} Skin</p>
          </div>
          <div class="my-[1rem]"></div>
          <div class="resp-text-2 font-digi">
            Buy for $ {dec(skininfo.priceusd, 2)}
          </div>
          <div class="fr-sc w-full">
            <div class="flex-1"></div>
            <TokenIcon token={"WETH"} />
            <TokenIcon token={"DEZ"} />
          </div>
          {enablebuy ? (
            <>
              {nils(vault) ? (
                <Tag
                  onClick={() => {
                    mmcon.login();
                  }}
                  className="bg-orange-500 font-digi resp-text--1 text-white"
                >
                  {"Connect Wallet"}
                </Tag>
              ) : (
                <Tag
                  onClick={() => {
                    // on_buy_click();
                  }}
                  className="fr-sc resp-gap-1  bg-transparent text-red-500 font-digi resp-text--1"
                >
                  <FontAwesomeIcon icon={faLock} />
                  <span>{"Locked"}</span>
                </Tag>
              )}
            </>
          ) : (
            <>
              <p class="text-white resp-text-1 font-digi">
                {"Unlocks in "}
                {from_time_mini(unlocks_at, now)}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const HeadSection = ({}) => {
  const appcon = useAppContext();
  const { tokmap, usd_to_tok_val } = appcon;

  const ccon = useChainPlayContext();
  const {
    vault,
    q_info,
    q_txns,
    info: skininfo,
    skin,
    unlocks_at,
    enablebuy,
  } = ccon;

  const [pop, set_pop] = useState(false);
  const [popdata, set_popdata] = useState({});
  const [popresp, set_popresp] = useState({});

  const open_popup = () => {
    console.log("open buy popup");
    set_pop(true);
    set_popdata({});
    set_popresp({});
  };

  const close_popup = () => {
    set_pop(false);
    set_popdata({});
    set_popresp({});
  };

  const actions = {
    buy: async (popdata) => {
      try {
        const { token, qty } = popdata;
        console.log("buy", { token, qty });

        if (nils(token)) throw new Error("token not found");
        if (nils(qty)) throw new Error("qty not found");
        if (qty < 1) throw new Error("qty must be greater than 1");
        if (qty > skininfo.max_qty)
          throw new Error(`qty must be less than ${skininfo.max_qty}`);

        set_popresp({
          loading: true,
          msgtype: "info",
          msg: `Buying ${qty} Skins`,
        });

        const con = await SkinsDirect_v1.get_contract();
        const paycon = await mm_asset_signer(token);
        let totcostusd = skininfo.priceusd * qty;
        console.log({ totcostusd });

        let totcost = usd_to_tok_val(totcostusd, token);
        totcost = parseFloat(dec(totcost, 6));
        console.log({ price: totcost });

        set_popresp({
          loading: true,
          msgtype: "info",
          msg: `Checking Balance`,
        });
        let bal = await paycon.balanceOf(vault);
        bal = parseFloat(tofeth(bal));
        console.log({ bal });
        if (bal < totcost) throw new Error("not enough balance");

        let alw = await paycon.allowance(vault, con.contractAddress);
        alw = parseFloat(tofeth(alw));
        console.log({ alw });
        if (alw < totcost) {
          set_popresp({
            loading: true,
            msgtype: "info",
            msg: `Spend Allowance: Please confirm transaction on MetaMask`,
          });
          let tx = await paycon.approve(
            con.contractAddress,
            toeth(totcost * 1.2),
          );
          await tx.wait();
          await cdelay(2 * 1e3);
        }

        set_popresp({
          loading: true,
          msgtype: "info",
          msg: `Buy Skins, Please confirm transaction on MetaMask`,
        });
        let tx = await con.buySkin(skininfo.name, qty, token);
        // console.log("buy response", tx);
        // let resp = await tx.wait();
        let resp = tx;
        console.log("buy receipt", resp);

        let queresp = await q_que_txn({
          hash: resp.hash,
          vault,
          type: "buy_skinsdirect",
          service: "skinsdirect",
        }).queryFn();
        console.log("queresp", queresp);

        set_popresp({
          loading: false,
          msgtype: "success",
          msg: `Txn Sucessful! your skins will be minted soon`,
        });
        window.open(polytxnlink(resp.hash));

        setTimeout(() => {
          q_txns.refetch();
        }, 8 * 1e3);
        setTimeout(() => {
          close_popup();
        }, 2000);
      } catch (err) {
        console.log(err);
        let errmsg = !nils(err.reason) ? err.reason : err.message;
        if (errmsg.length > 100) errmsg = errmsg.slice(0, 100) + "...";

        set_popresp({
          loading: false,
          msgtype: "error",
          msg: `Error:${errmsg}`,
        });
        // setTimeout(() => {
        //   close_popup();
        // }, 2000);
      }
    },
  };

  const skincard = !nils(skininfo) && (
    <>
      <SkinBuyCard
        {...{
          boxtheme,
          skininfo,
          enablebuy,
          unlocks_at,
          on_buy_click: () => {
            open_popup();
          },
        }}
      />
    </>
  );
  const side_info = !nils(skininfo) && (
    <>
      <div class="fc-cc h-full resp-text-1">
        <p class="text-white font-digi resp-text-3">chainplay.gg</p>
        <Link
          className="cursor-pointer"
          to="https://chainplay.gg/"
          target="_blank"
        >
          <p className="">
            {"come check out "}
            <span className="text-orange-300">https://chainplay.gg/</span>
          </p>
        </Link>
        <div class="xs:w-[10rem] lg:w-[25rem] aspect-[2200/1600]">
          <Img img={get_skin_transparent_img(skininfo.name)} />
        </div>
      </div>
    </>
  );

  const popup_section = !nils(skininfo) && (
    <PopUp
      {...{
        openstate: pop,
        overlayclose: false,
        onClose: close_popup,
        wrapcn: twMerge("top-[5rem] left-[50%]"),
        innercn: twMerge(" translate-x-[-50%] translate-y-[0%]"),
      }}
    >
      <Card
        className={twMerge(
          "card-dark-bg xs:w-[95vw] lg:w-[30rem]",
          "backdrop-blur-xl  rounded-xl resp-text--1",
        )}
      >
        <PopupCloseBtn closepopup={close_popup} />
        <p class={twMerge("font-digi resp-text-2 text-center my-2")}>
          Buy {skininfo.name} Skin
        </p>
        <p className="text-slate-300 resp-text--3">{jstr(popdata)}</p>
        <div class="fr-cc">
          <InpText
            {...{
              id: "inp_qty",
              label: "Quantity",
              placeholder: "enter qty",
              def_val: null,
              setter: (v) => {
                v = parseInt(v);
                if (nils(v)) v = 0;
                set_popdata({ ...popdata, qty: v });
              },
            }}
          />
        </div>
        {popdata.qty > 0 && (
          <>
            <p className="fr-cc resp-text-1 w-full text-center my-2 text-white font-digi">
              <span>Total Cost: </span>
              <FontAwesomeIcon icon={faUsd} />
              <span>{dec(popdata.qty * skininfo.priceusd, 2)}</span>
            </p>
            {popresp.loading == true ? (
              <p></p>
            ) : (
              <div className="fr-cc gap-2">
                <p className="font-digi resp-text-0">Buy Using:</p>
                {[
                  ["WETH", "bg-purple-500/40 text-white"],
                  ["DEZ", "bg-acc4/40 text-white"],
                ].map(([token, cn]) => {
                  return (
                    <Tag
                      onClick={async () => {
                        const fn = async () => {
                          set_popdata({ ...popdata, token });
                          actions.buy({ ...popdata, token });
                        };
                        fn();
                      }}
                      className={twMerge("font-digi fr-sc resp-gap-1", cn)}
                    >
                      <TokenIcon token={token} />
                      <span>{token}</span>
                    </Tag>
                  );
                })}
              </div>
            )}
          </>
        )}

        {!_.isEmpty(popresp) && (
          <div
            class={twMerge(
              "fr-sc w-full px-4 py-2 border-2 rounded-md",
              popresp.msgtype == "error"
                ? "text-red-400 border-red-400"
                : popresp.msgtype == "info"
                  ? "text-blue-400 border-blue-400"
                  : popresp.msgtype == "success"
                    ? "text-green-400 border-green-400"
                    : "",
            )}
          >
            {popresp.loading == true && <Loader01c size="s" />}
            <p class={"flex-1 font-digi resp-text--1"}>{popresp.msg}</p>
          </div>
        )}
      </Card>
    </PopUp>
  );

  if (!qissuccesss(ccon.q_info) || nils(skininfo)) return null;
  return (
    <>
      {popup_section}
      <div class="xs:flex  lg:hidden fc-cc">
        {skincard}
        {side_info}
      </div>
      <div class="xs:hidden lg:block">
        <div class="bg-gradient-to-t from-orange-700/30 to-r2dark/20 backdrop-blur-xl h-[500px] py-[1rem]">
          <div
            class={twMerge(
              page_w,
              "h-full flex flex-row justify-around items-stretch",
            )}
          >
            {skincard}
            {side_info}
          </div>
        </div>
      </div>
    </>
  );
};

const TxRow = ({ tx }) => {
  const [nftspopup, set_nftspopup] = useState(false);
  let date =
    moment().diff(tx.date, "seconds") < 5 * 60
      ? moment(tx.date).fromNow()
      : iso_format(tx.date, "DD-MMM YY, h:mm:ss a");
  let hash = tx.id.split(":")[0];

  const amt = getv(tx, "connects.amt");
  const token = getv(tx, "connects.token");

  const td_amt = (
    <div className="fr-sc resp-gap-1 resp-text--2">
      <div className="flex-1"></div>
      <div className="xs:w-[1rem] lg:w-[2rem]">
        <TokenIcon token={token ?? "WETH"} />
      </div>
      <span>{dec(amt, tokdecn(token))}</span>
    </div>
  );

  const td_polyimg = (
    <Tag redirect={polytxnlink(hash)} className="xs:w-[1.5rem] lg:w-[3rem]">
      <Img img={polychainimg} />
    </Tag>
  );

  const td_names = <div className="fc-ss resp-gap-1 resp-text--2"></div>;

  const td_type = (
    <span className="resp-text--2">
      {_.chain(tx.type).split("_").map(_.upperCase).join(" ").value()}
    </span>
  );

  const td_date = <span className="resp-text--2">{date}</span>;

  const qty = getv(tx, "connects.qty");

  const allminted = getv(tx, "connects_post.minted") === true;

  const td_inout = (
    <>
      {tx.type == "buy" && (
        <>
          {allminted ? (
            <Tag className="bg-orange-500/20 text-white">
              {qty} skins Minted
            </Tag>
          ) : (
            <Tag className="fr-sc resp-gap-1 bg-blue-500/20 text-white">
              <FontAwesomeIcon icon={faSpinner} />
              {qty} skins minting..
            </Tag>
          )}
        </>
      )}
    </>
  );

  const asset_groups = useMemo(() => {
    let assets = getv(tx, "connects.assets") || [];
    assets = assets.map((e) => {
      e.post = getv(tx, `connects_post.mintmap.${e.idx}`);
      return e;
    });
    return _.groupBy(assets, "skin");
  }, [jstr(tx)]);

  const td_view = (
    <>
      {tx.type == "buy" && (
        <>
          <Tag
            onClick={() => {
              set_nftspopup(true);
            }}
            className="bg-acc0/40 transform -skew-x-12 text-white gap-1 fr-cc"
          >
            <span>View NFTs</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Tag>

          <PopUp
            wrapcn={twMerge("top-[5rem] left-[50%]")}
            innercn={twMerge(" translate-x-[-50%] translate-y-[0%]")}
            openstate={nftspopup}
            overlayclose={false}
          >
            <Card
              className={
                "xs:w-full card-basic-bg card-grad-border md:w-[50rem] max-w-[95rem] relative"
              }
            >
              <div className="fr-cc">
                <div className="flex-1"></div>
                <Tag
                  onClick={() => set_nftspopup(false)}
                  className={twMerge(
                    "fc-cc rounded-full bg-red-500 ",
                    "absolute md:right-[-1rem] md:top-[-1rem] md:w-[2rem] md:h-[2rem]",
                    "absolute xs:right-[-0.5rem] xs:top-[-0.5rem] xs:w-[1rem] xs:h-[1rem]",
                  )}
                >
                  <FontAwesomeIcon icon={faClose} />
                </Tag>
              </div>
              <div class="w-full">
                {_.isEmpty(asset_groups) ? (
                  <p class="resp-text--1">No Assets Yet</p>
                ) : (
                  _.entries(asset_groups).map(([skin, assets]) => {
                    return (
                      <div class="resp-text--1 font-digi p-2 my-2 border-b border-acc4">
                        <p class="text-white font-digi resp-text-3">
                          Skin: {skin}
                        </p>
                        {assets.map((a) => {
                          let m = a.post ?? { minted: false };
                          return (
                            <div class="fr-sc resp-text-0 gap-2 my-2 overflow-x-auto">
                              <span class="text-right w-[4rem]">
                                #{a.idx} / {getv(tx, "connects.qty")}
                              </span>
                              {m.minted ? (
                                <>
                                  <Link to={polytxnlink(m.minthash)}>
                                    <div class="xs:w-[1.5rem] lg:w-[2.5rem] aspect-[1/1]">
                                      <Img img={polychainimg} />
                                    </div>
                                  </Link>
                                  <span>SkinID: #{m.skinid}</span>
                                  <div class="w-[2rem]"></div>
                                  <span>
                                    minted at{" "}
                                    {iso_format(
                                      m.date,
                                      "DD-MMM YYYY, h:mm:ss a",
                                    )}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon icon={faSpinner} />
                                  <span>Minting...</span>
                                </>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                )}
              </div>
            </Card>
          </PopUp>
        </>
      )}
    </>
  );

  return (
    <>
      <tr className={"thintdrow xs:hidden md:block"}>
        <td className={""}>{td_polyimg}</td>
        <td className={""}>{td_type}</td>
        <td className={""}>{td_amt}</td>
        <td className={""}>{td_date}</td>
        <td className={""}>{td_inout}</td>
        <td className={""}>{td_view}</td>
      </tr>
      <tr
        className={"thintdrow xs:block md:hidden border-b border-transparent"}
      >
        <td className={""} colSpan={2}>
          {td_polyimg}
        </td>
        <td className={""} colSpan={2}>
          {td_amt}
        </td>
        <td className={""} colSpan={2}>
          {td_date}
        </td>
      </tr>
      <tr className={"thintdrow xs:block md:hidden border-b border-acc0"}>
        <td className="" colSpan={6}>
          <div className="fr-sc">
            {td_inout}
            {td_view}
          </div>
        </td>
      </tr>
    </>
  );
};

const Transactions = ({ txns }) => {
  return (
    <Card className={"mx-auto card-basic-bg xs:w-full md:w-max  overflow-auto"}>
      {_.isEmpty(txns) ? (
        <p className="resp-text--1">No Transactions Yet</p>
      ) : (
        <table className={twMerge(Array2D_Tablecn.table_cn, "w-full")}>
          <tbody>
            {(txns || []).map((tx) => {
              return <TxRow tx={tx} />;
            })}
          </tbody>
        </table>
      )}
    </Card>
  );
};

export const ChainPlayPage = () => {
  const [q] = useQueries([q_sheet_pages_chainplay({})]);
  const o = useMemo(() => getv(q, "data.result"), [q.dataUpdatedAt]);

  const skin = "ChainPlay";
  const { vault } = useMetaContext();
  const [q_info, q_txns] = useQueries([
    q_skins_direct_info({
      skin,
    }),
    q_skins_direct_txns(
      { vault },
      {
        enabled: !nils(vault),
        staleTime: 30 * 1e3,
        refetchInterval: 30 * 1e3,
      },
    ),
  ]);
  const info = useMemo(
    () => getv(q_info, "data.result"),
    [q_info.dataUpdatedAt],
  );
  const txns = useMemo(
    () => getv(q_txns, "data.result"),
    [q_txns.dataUpdatedAt],
  );

  const unlocks_at = null;
  const enablebuy = true;

  const ccon = {
    vault,
    skin,
    unlocks_at,
    enablebuy,
    q_info,
    info,
    q_txns,
    txns,
  };

  return (
    <>
      <ChainPlayContext.Provider value={ccon}>
        <div class="h-page">
          <HeadSection />
          <div class={page_w}>
            <div class="h-[1rem]"></div>
            {qissuccesss(q) && (
              <>
                <div class="grid xs:grid-cols-3 p-2  lg:grid-cols-6 gap-2 resp-text--1  text-slate-200">
                  <div class="col-span-3">
                    <Card className={twMerge(card_cn, " w-full h-full")}>
                      <div
                        class={twMerge(
                          highlight_txt,
                          "resp-text-1 font-digi text-center",
                        )}
                      >
                        {getv(o, "grid1.head")}
                      </div>
                      <hr className="my-2" />
                      <>
                        {(getv(o, "grid1.info") || []).map((r) => {
                          return (
                            <div class="fr-sc w-full resp-gap-2">
                              <FontAwesomeIcon
                                className="text-[0.5rem]"
                                icon={faCircle}
                              />
                              {r.map((e) => {
                                return <p class="my-1 font-mon px-1">{e}</p>;
                              })}
                            </div>
                          );
                        })}
                      </>
                      <hr className="my-2" />

                      <TableView
                        head="Rounds"
                        d={getv(o, "grid1.rounds") || []}
                      />

                      <TableView
                        head="Segments"
                        d={getv(o, "grid1.segments") || []}
                      />
                    </Card>
                  </div>
                  <div class="col-span-3">
                    <Card className={twMerge(card_cn, " w-full h-full")}>
                      <div
                        class={twMerge(
                          highlight_txt,
                          "resp-text-1 font-digi text-center",
                        )}
                      >
                        {getv(o, "grid2.head")}
                      </div>
                      <TableView
                        head="Scoring"
                        d={getv(o, "grid2.scoring") || []}
                      />
                      <div class="flex flex-row justify-around items-start gap-2">
                        <TableView head="" d={getv(o, "grid2.posmap") || []} />
                        <TableView head="" d={getv(o, "grid2.overall") || []} />
                      </div>
                    </Card>
                  </div>
                </div>
              </>
            )}

            {!_.isEmpty(txns) && <Transactions txns={txns} />}
            <div class="h-[5rem]"></div>
          </div>
        </div>
      </ChainPlayContext.Provider>
    </>
  );
};
