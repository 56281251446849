import { ethers } from "ethers";
import { cdelay, iso, nano, nils, tofeth } from "../utils/utils.js";
import WETH_MockToken from "./WETH_MockToken/WETH_MockTokenContract.js";
import _ from "lodash";
import { contractAddress_list } from "./contracts.js";
import { polygon } from "thirdweb/chains";
import abimap, { abimethodmap } from "./abimap.js";
import {
  getContract,
  prepareContractCall,
  sendTransaction,
  waitForReceipt,
} from "thirdweb";
import { thirdweb_client } from "../views/ThirdWebLogin.js";

export const asset_types_map = _.chain([
  ["core", 721, "Core", "0xCE8090dE88Bba13d3CEa5d73F8Baf1F1C1a61B16"],
  ["skin", 721, "Skin", "0xcd0783c0e2b0a68a64ba7c5e0f99097945397cf3"],
  ["god", 721, "GOD Token", "0x28AaBbd51A634d186F79ffbfA84FB70D119DbB05"],
  [
    "corelootboxv2",
    1155,
    "Core LootBox V2",
    "0x20dd967458922cDC21b16F69e043c7C918bcE8eF",
  ],
  [
    "skinlootboxv1",
    1155,
    "Skin LootBox V1",
    "0xbcDEB62e65666A13b4dF9fab76258b3164Da18bd",
  ],
  [
    "skinlootboxv101",
    1155,
    "Hallow Lootbox",
    "0x459698b869a671ecdF7c7714B101eb8B8891C2C5",
  ],
  [
    "skingamelootbox",
    1155,
    "Rarity Box",
    "0x7D16c167f9d9241A538ade09FD64AC25cA50132E",
  ],

  ["bgc", 20, "BGC", contractAddress_list.bikecredits],
  ["BGC", 20, "BGC", contractAddress_list.bikecredits],
  ["sgc", 20, "SGC", contractAddress_list.skincredits],
  ["dez", 20, "DEZ", contractAddress_list.dez],
  ["DEZ", 20, "DEZ", contractAddress_list.dez],
  ["weth", 20, "WETH", contractAddress_list.weth],
  ["WETH", 20, "WETH", contractAddress_list.weth],

  ["weth-mock01", 20, "WETH-mock01", contractAddress_list["weth-mock01"]],

  ["lc01", 20, "LC01", contractAddress_list["lc01"]],
  ["LC01", 20, "LC01", contractAddress_list["lc01"]],

  ["lc02", 20, "LC02", contractAddress_list["lc02"]],

  ["lc", 20, "LC", contractAddress_list["lc"]],
  ["LC", 20, "LC", contractAddress_list["lc"]],
])
  .map((e) => {
    let [asset_type, erc, name, address] = e;
    address = address.toLowerCase();
    // let infofn = infofns[asset_type];
    return { asset_type, name, address, erc };
  })
  .keyBy("asset_type")
  .value();

const erc_abis = {
  721: [
    "function transferFrom(address from, address to, uint256 tokenId) external",
    "function approve(address to, uint256 tokenId) public",
    "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    "function setApprovalForAll(address operator, bool approved) public",
  ],
  1155: [
    "function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data) external",
    "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    "function setApprovalForAll(address operator, bool approved) public",
  ],
  20: [
    "function balanceOf(address account) external view returns (uint256)",
    "function transfer(address to, uint256 amount) external returns (bool)",
    "function allowance(address owner, address spender) external view returns (uint256)",
    "function approve(address spender, uint256 amount) external returns (bool)",
  ],
};
export const mm_asset_signer = async (asset_type, k, address) => {
  let erc = null;
  if (!nils(asset_type)) {
    let asty = asset_types_map[asset_type];
    erc = asty.erc;
    address = asty.address;
  } else if (!nils(k)) {
    erc = k;
  }
  if (!window.ethereum) throw new Error("No ethereum provider found");
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(address, erc_abis[erc], signer);
  return contract;
};

export const t3_asset_signer = async (asset_type, k, address) => {
  let erc = null;
  if (!nils(asset_type)) {
    let asty = asset_types_map[asset_type];
    erc = asty.erc;
    address = asty.address;
  } else if (!nils(k)) {
    erc = k;
  }
  if (!polygon.rpc) throw new Error("No polygon rpc found");
  const provider = new ethers.JsonRpcProvider(polygon.rpc);
  const contract = new ethers.Contract(address, erc_abis[erc], provider);
  return contract;
};

export const t3_contract_call = async (
  con_k,
  method,
  params,
  type = "data",
  wait = false,
  ext = {},
) => {
  // console.log("t3:con_k", { con_k, method, params, type, wait, ext, });
  let contract_address = con_k.startsWith("0x")
    ? con_k
    : contractAddress_list[con_k];
  let rpc = polygon.rpc;
  let txk = `t3:${con_k}:${method}`;

  if (type == "data") {
    let abi = abimap[con_k];
    let provider = new ethers.JsonRpcProvider(rpc);
    let con = new ethers.Contract(contract_address, abi, provider);
    let resp = await con[method](...params);
    console.log(`${txk}:response`, resp);
    return resp;
  } else if (type == "txn") {
    let abi_methods = !nils(ext.use_abimethods_k)
      ? abimethodmap[ext.use_abimethods_k]
      : abimethodmap[con_k];
    let contract = await getContract({
      address: contract_address,
      chain: polygon,
      client: thirdweb_client,
    });
    let txn = await prepareContractCall({
      contract,
      method: method.startsWith("function ") ? method : abi_methods[method],
      params,
    });
    let resp = await sendTransaction({
      account: ext.active_account,
      transaction: txn,
    });
    console.log(`${txk}:response`, resp.transactionHash);
    resp.hash = resp.transactionHash;
    if (wait == true) {
      resp = await waitForReceipt({
        client: thirdweb_client,
        transactionHash: resp.transactionHash,
        chain: polygon,
      });
      resp.hash = resp.transactionHash;
      console.log(`${txk}:done_receipt`, resp.transactionHash);
    }
    return resp;
  }
  return null;
};

export const get_dez_balance = async (addr) => {
  try {
    let con = await mm_asset_signer(null, 20, contractAddress_list.dez);
    let bal_wei = await con.balanceOf(addr);
    let bal = tofeth(bal_wei);
    return bal;
  } catch (err) {
    return null;
  }
};

export const get_weth_balance = async (addr) => {
  try {
    let con = await mm_asset_signer(null, 20, contractAddress_list.weth);
    let bal_wei = await con.balanceOf(addr);
    let bal = tofeth(bal_wei);
    return bal;
  } catch (err) {
    return null;
  }
};
export const get_t3_weth_balance = async (addr) => {
  try {
    let con = await t3_asset_signer("weth");
    let bal_wei = await con.balanceOf(addr);
    let bal = tofeth(bal_wei);
    return bal;
  } catch (err) {
    return null;
  }
};
export const get_t3_dez_balance = async (addr) => {
  try {
    let con = await t3_asset_signer("dez");
    let bal_wei = await con.balanceOf(addr);
    let bal = tofeth(bal_wei);
    return bal;
  } catch (err) {
    return null;
  }
};

export const que_con_fn_runner = async (fn, id) => {
  let i = 0;
  let retry = 0;
  let mxretry = 3;
  do {
    let now = nano();

    i++;
    if (i > 10) return null;
    console.log(id, { i, retry });
    try {
      let k = localStorage.getItem("mm_que_lock");
      let now = nano();

      if (!nils(k) && now - parseInt(k) > 5 * 1e3) {
        localStorage.removeItem("mm_que_lock");
        k = null;
      }

      if (!nils(k)) {
        await cdelay(1000);
        continue;
      }

      localStorage.setItem("mm_que_lock", now);

      let resp = await fn();

      localStorage.removeItem("mm_que_lock");

      return resp;
    } catch (err) {
      retry++;
      await cdelay(1 * 1e3);
      if (retry >= mxretry) {
        throw err;
      }
      continue;
    }
  } while (true || retry < mxretry);
};

export const get_gasinfo = async () => {
  return {
    gasLimit: 280000,
  };
};
