const ethers = require("ethers");
const abi = require("./abi.json");

const { contractAddress_list } = require("../contracts.js");
const { nils } = require("../../utils/utils");

const getgasinfo = async () => ({});

class CoreLootbox_v2_Contract {
  constructor({ contractAddress, provider, signer, nosigner = false }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    if (nosigner === true)
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        provider,
      );
    else
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        signer,
      );
  }

  async setPrice(_price, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setPrice(_price, info);
    return tx;
  }

  async setURI(newuri, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setURI(newuri, info);
    return tx;
  }

  async getURI() {
    return await this.contract.getURI();
  }

  async set_maxboxes(_maxboxes, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.set_maxboxes(_maxboxes, info);
    return tx;
  }

  async o_mintLootbox(to, qty, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.o_mintLootbox(to, qty, info);
    return tx;
  }

  async buyLootbox(qty, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.buyLootbox(qty, info);
    return tx;
  }

  async o_burnLootbox(account, qty, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.o_burnLootbox(account, qty, info);
    return tx;
  }

  async openLootbox(qty, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.openLootbox(qty, info);
    return tx;
  }

  async getCounts() {
    let maxboxes = Number(await this.contract.maxboxes());
    let minted = Number(await this.contract.minted());
    let burned = Number(await this.contract.burned());
    let available = maxboxes - minted;
    let closed = minted - burned;
    return {
      maxboxes,
      minted,
      burned,
      available,
      closed,
      token: "WETH",
    };
  }

  async getPrice() {
    let price = await this.contract.price();
    return price;
  }

  async accountOwns(account) {
    return await this.contract.accountOwns(account);
  }

  async viewFunds() {
    return await this.contract.viewFunds();
  }

  async withdrawFunds(toAddress, amount, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.withdrawFunds(toAddress, amount, info);
    return tx;
  }
}

const k = "corelootboxv2";
const get_contract_address = () => {
  return contractAddress_list[k];
};

const get_CoreLootbox_v2_Contract = async (ext = {}) => {
  const contractAddress = get_contract_address();
  const provider = !nils(ext.rpc)
    ? new ethers.JsonRpcProvider(ext.rpc)
    : new ethers.BrowserProvider(window.ethereum);
  const signer = ext.nosigner === true ? null : await provider.getSigner();

  const pars = { contractAddress, provider, signer, ...ext };
  const runner = new CoreLootbox_v2_Contract(pars);
  return runner;
};

const CoreLootbox_v2 = {
  class: CoreLootbox_v2_Contract,
  get_contract: get_CoreLootbox_v2_Contract,
};

module.exports = CoreLootbox_v2;
