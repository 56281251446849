const o = {
  o_burnLootbox:
    "function o_burnLootbox(address account, uint256 qty, uint256 boxtype)",
  burnBike: "function burnBike(uint256 hid, uint256 reward) external",
  requestBurn: "function requestBurn(uint256 hid) external",
  revokeRequest: "function revokeRequest(uint256 hid) external",
  getBikeBurnDoc:
    "function getBikeBurnDoc(uint256 hid) view returns (uint256 hid, bool burned, address owner, uint256 reward, uint256 burned_at)",
};

export default o;
