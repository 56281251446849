import { twMerge } from "tailwind-merge";
import { BImg, Card, Img, Tag } from "../components/utilityComps.js";
import { useEffect, useMemo, useState } from "react";
import { cdelay, dec, getv, iso_format, jstr, nils } from "../utils/utils.js";
import { Loader01c } from "../components/anims.js";
import { useMetaContext } from "../wrappers/MetaMaskWrapper.js";
import {
  q_lootbox_box,
  q_lootbox_openbox,
  qissuccesss,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import { PopUp } from "../components/popup.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faBiohazard,
  faCircle,
  faEarth,
  faF,
  faFemale,
  faFire,
  faFlag,
  faHammer,
  faLock,
  faMale,
  faMars,
  faMinus,
  faTeletype,
  faTriangleExclamation,
  faTrophy,
  faVenus,
  faWater,
  faWaveSquare,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useMintContext } from "./MintPage.js";
import { polychainimg } from "../utils/links.js";

const lootbox_tokenId = 1;

export const MiniLootBox = ({ b0, qoboxes }) => {
  const mmcon = useMetaContext();
  const { mmaccount: vault } = mmcon;

  const boximg = "https://i.imgur.com/5LJPt3M.png";
  const polyimg = polychainimg

  const [view, set_view] = useState(false);
  const [popup, set_popup] = useState(false);
  const [boxloading, set_boxloading] = useState(false);
  const init_is_opened = useMemo(() => {
    return b0.is_opened;
  }, []);

  const [qobox] = useQueries([
    q_lootbox_box(
      {
        vault,
        lootid: b0.lootid,
        ...(init_is_opened ? { test: true } : {}),
      },
      { enabled: view }
    ),
  ]);
  const box = useMemo(() => {
    if (!qissuccesss(qobox)) return b0;
    return getv(qobox, "data.result");
  }, [qobox.dataUpdatedAt, jstr(b0)]);
  const txn_hash = useMemo(() => {
    if (box.lock) return getv(box, "lock_dets.txn_hash");
    else return getv(box, "txninfo.txn_hash");
  }, [box]);
  const date = useMemo(() => {
    if (box.lock) return getv(box, "lock_dets.lock_at");
    else return getv(box, "assigned_at");
  }, [box]);

  const open_loot = async () => {
    set_boxloading(true);
    let resp = await q_lootbox_openbox({ vault, lootid: b0.lootid }).queryFn();
    if (resp.status == "success") {
      await cdelay(1000);
      set_view(false);
      await cdelay(2000);
    }
    qobox.refetch();
    set_popup(true);
    await cdelay(500);
    set_boxloading(false);
    qoboxes.refetch();
  };

  const view_loot = async () => {
    set_view(true);
    if (nils(box)) {
      setTimeout(() => {
        set_popup(true);
      }, 500);
    } else {
      set_popup(true);
    }
  };

  return (
    <div className="w-full">
      <Card className={twMerge("p-4 w-full overflow-hidden")}>
        <div className="relative w-[12rem] h-[12rem] mx-auto ">
          {box.lock == true ? (
            <>
              <div className="absolute animate-fade-in w-[12rem] h-[12rem] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] bg-red-500 z-[5] blur-3xl"></div>
              <Img
                img={boximg}
                className={"absolute w-[12rem] h-[12rem] z-[10] grayscale"}
              />
              <div className="absolute w-[12rem] h-[12rem] z-[15] top-[40%] left-[50%] ">
                <FontAwesomeIcon
                  icon={faLock}
                  className="text-red-500 text-[3rem]"
                />
              </div>
            </>
          ) : box.is_opened ? (
            <>
              <Img img={boximg} className={"w-[12rem] h-[12rem] grayscale"} />
              <div className="absolute top-[30%] left-[20%] w-max">
                <p className="text-[1.5rem]  bg-acc0/40 p-2 rounded-md text-dark font-digi -rotate-[25deg]">
                  Opened
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="absolute animate-fade-in w-[12rem] h-[12rem] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] bg-acc0 z-[5] blur-3xl"></div>
              <Img
                img={boximg}
                className={"absolute w-[12rem] h-[12rem] z-[10]"}
              />
            </>
          )}
        </div>
        <div className="fr-sc gap-2 w-full">
          {!nils(txn_hash) && (
            <>
              <Tag redirect={`https://polygonscan.com/tx/${txn_hash}`}>
                <Img className={"w-[1.5rem]"} img={polyimg} />
              </Tag>
            </>
          )}
          <div className="flex-1"></div>
          {boxloading && <Loader01c size="s" />}
          {box.lock == true ? (
            <>
              <Loader01c size="s" />
              <Tag
                onClick={() => {
                  if (boxloading) return;
                }}
                className="text-acc0 -skew-x-12 text-[0.8rem]"
              >
                Confirming Transaction...
              </Tag>
            </>
          ) : box.is_opened ? (
            <Tag
              onClick={() => {
                if (boxloading) return;
                view_loot();
              }}
              className="text-acc0 -skew-x-12 border border-acc0 text-[0.8rem]"
            >
              View Loot
            </Tag>
          ) : (
            <Tag
              onClick={() => {
                if (boxloading) return;
                open_loot();
              }}
              className="bg-acc0 shadow shadow-acc0 -skew-x-12 border border-acc0 text-[0.8rem] text-black"
            >
              Open Loot
            </Tag>
          )}
        </div>
        <div className="fr-sc gap-2 w-full">
          <p className="my-2 text-[0.7rem] px-2">
            {iso_format(date, "MMM-DD 'YY, hh:mmA")}
          </p>
        </div>
      </Card>

      <PopUp
        wrapcn={twMerge("top-[5rem] left-[50%]")}
        innercn={twMerge(" translate-x-[-50%] translate-y-[0%]")}
        openstate={popup}
        overlayclose={true}
        onClose={() => set_popup(false)}
      >
        <Card className={twMerge("max-w-[98vw] w-[60rem] p-4")}>
          {qobox.isLoading && <Loader01c />}
          {qissuccesss(qobox) && !nils(box) && (
            <>
              <div className="grid xs:grid-cols-1 gap-2 flex-wrap">
                <BikeCard bike={getv(box, "bikeinfo")} />

                {box.gets_god_token && (
                  <div className="p-2 bg-dark rounded-md ">
                    <div className="grid grid-cols-3">
                      <div className="col-span-1">
                        <Img
                          className={"w-full"}
                          img="https://imgur.com/lYds0vd.png"
                        />
                      </div>
                      <div className="col-span-2">
                        <p className="text-acc0 font-digi text-[1.5rem] mt-[2rem] ml-[2rem]">
                          $GOD NFT
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </Card>
      </PopUp>
    </div>
  );
};

const MiniStatCard = ({ icon, head, txt, icn, cn, wrapcn }) => {
  return (
    <div
      className={twMerge("shadow shadow-black px-2 py-3 rounded-sm", wrapcn)}
    >
      <div className="fr-sc gap-2">
        <div className={twMerge("px-2", icn)}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className="flex flex-col justify-center items-start">
          <div className="font-mon text-[0.7rem] text-slate-300">{head}</div>
          <div
            className={twMerge(
              "font-mon text-[0.8rem] text-white font-bold",
              cn
            )}
          >
            {txt}
          </div>
        </div>
      </div>
    </div>
  );
};

function BikeCard({ bike: b }) {
  return (
    <div className="bg-dark p-2 grid grid-cols-3 gap-2">
      <div className="col-span-1">
        <BImg hex_code={b.hex_code} className={"w-full h-full max-h-[8rem]"} />
      </div>
      <div className="col-span-2">
        <div className="font-digi text-[1.2rem] text-center my-2 text-acc0">
          {b.name}
        </div>
        <div className="grid grid-cols-2">
          {[
            ["Type", _.capitalize(b.type), faTeletype],

            b.gender == "male"
              ? [
                  "Gender",
                  _.capitalize(b.gender),
                  faVenus,
                  "text-blue-400",
                  "text-blue-400",
                ]
              : [
                  "Gender",
                  _.capitalize(b.gender),
                  faVenus,
                  "text-pink-400",
                  "text-pink-400",
                ],

            ["F.No", `F${b.fno}`, faF],
            [
              "Element",
              _.capitalize(b.element),
              ...((b.element == "earth" && [
                faEarth,
                "text-orange-900",
                "text-orange-900",
              ]) ||
                (b.element == "water" && [
                  faWater,
                  "text-blue-400",
                  "text-blue-400",
                ]) ||
                (b.element == "fire" && [
                  faFire,
                  "text-orange-400",
                  "text-orange-400",
                ]) ||
                (b.element == "metal" && [
                  faHammer,
                  "text-slate-900",
                  "text-slate-400",
                ]) ||
                []),
            ],
            [
              "Color",
              _.chain(b.color).split("-").map(_.capitalize).join(" ").value(),
              faCircle,
              `text-${b.color}`,
              "",
            ],
          ].map(([head, txt, icon, icn, cn]) => {
            return (
              <MiniStatCard
                {...{
                  icon,
                  head,
                  txt,
                  icn,
                  cn,
                  wrapcn: head == "Color" ? "col-span-2" : "",
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

const boximg = "https://i.imgur.com/5LJPt3M.png";
const polyimg = polychainimg
const polybase = "https://mumbai.polygonscan.com";
export const ClosedBoxes = ({ closed_n }) => {
  const { mmaccount: vault } = useMetaContext();
  const mintcon = useMintContext();

  const [resp, set_resp] = useState({});
  const [popup, set_popup] = useState(false);
  const [loading, set_loading] = useState(false);

  const openbox = async () => {
    set_loading(true);
    const doc = { vault };
    let resp = await q_lootbox_openbox(doc).queryFn();
    // console.log(resp);
    if (getv(resp, "status") == "success") {
      set_resp(resp);
      set_popup(true);
    } else {
      let err = getv(resp, "err") ?? "Server Error Occured";
      set_resp({ status: "error", err });
    }

    if (mintcon) {
      await cdelay(500);
      mintcon.qoboxes.refetch();
    }

    set_loading(false);
  };

  // useEffect(() => {
  //   console.log(resp);
  // }, [resp]);

  if (closed_n == 0)
    return (
      <>
        <p className="text-acc0">No Closed LootBoxes owned currently</p>
      </>
    );
  return (
    <div className=" rounded-md bg-reg fr-cc w-max">
      <Card className={twMerge("p-4 pl-[2rem] overflow-hidden w-max")}>
        <div className="fr-cc">
          <div className="relative w-[12rem] h-[12rem] mx-auto ">
            <div className="absolute animate-fade-in w-[12rem] h-[12rem] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] bg-acc0 z-[5] blur-3xl"></div>
            <Img
              img={boximg}
              className={"absolute w-[12rem] h-[12rem] z-[10]"}
            />
          </div>
          <div className="p-4">
            <p className="text-acc0 font-digi text-[2rem]">x {closed_n}</p>
          </div>
        </div>
        <div className="fr-sc my-4">
          <div className="flex-1"></div>
          {loading && <Loader01c size="s" />}
          <Tag
            onClick={() => {
              if (loading) return;
              openbox();
            }}
            className="bg-acc0 -skew-x-12 text-black font-digi"
          >
            Open 1 Box
          </Tag>
        </div>
        {!nils(resp.err) && (
          <div className="fr-sc my-4">
            <p className="text-red-400">{!nils(resp.err)}</p>
          </div>
        )}
      </Card>

      <PopUp
        wrapcn={twMerge("top-[5rem] left-[50%]")}
        innercn={twMerge(" translate-x-[-50%] translate-y-[0%]")}
        openstate={popup}
        overlayclose={true}
        onClose={() => set_popup(false)}
      >
        <LootBoxAssets box={getv(resp, "result.openedlootbox")} />
      </PopUp>
    </div>
  );
};

export const LockedBoxes = ({ lock_s }) => {
  if (_.isEmpty(lock_s))
    return (
      <>
        <p className="text-acc0">No LootBoxes under Processing</p>
      </>
    );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
      {lock_s.map((l) => {
        let { txn_hash, lootids } = l;
        let n = lootids.length;
        return (
          <Card className={twMerge("p-4 pl-[2rem] overflow-hidden w-full")}>
            <div className="fr-cc">
              <div className="relative w-[12rem] h-[12rem] mx-auto ">
                <Img
                  img={boximg}
                  className={"absolute w-[12rem] h-[12rem] z-[10] grayscale"}
                />
                <div className="absolute w-[12rem] h-[12rem] z-[15] top-[40%] left-[50%] ">
                  <FontAwesomeIcon
                    icon={faLock}
                    className="text-red-500 text-[3rem]"
                  />
                </div>
                <div className="absolute animate-fade-in w-[12rem] h-[12rem] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] bg-red-500 z-[5] blur-3xl"></div>
              </div>
              <div className="p-4">
                <p className="text-acc0 font-digi text-[2rem]">x {n}</p>
              </div>
            </div>
            <div className="fr-sc my-4">
              <Link to={`${polybase}/tx/${txn_hash}`} target="_blank">
                <Img img={polyimg} className={"w-[2rem]"} />
              </Link>
              <Tag className="text-acc0 italic">Processing Txn</Tag>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export const OpenedBoxes = ({ open_s }) => {
  if (_.isEmpty(open_s)) return <p className="text-acc0">No Opened Boxes</p>;
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
      {open_s.map((bo) => {
        if (nils(bo)) return <></>;
        // console.log(bo);
        return <OpenedBox bo={bo} />;
      })}
    </div>
  );
};

export const OpenedBox = ({ bo }) => {
  const { mmaccount: vault } = useMetaContext();

  const [view, set_view] = useState(false);
  const [popup, set_popup] = useState(false);
  const { lootid, open_dets } = useMemo(() => {
    // console.log("bo", bo);
    return {
      lootid: getv(bo, "lootid") ?? null,
      open_dets: getv(bo, "open_dets") ?? null,
    };
  }, [jstr(bo)]);
  const [qobox] = useQueries([
    q_lootbox_box({ lootid, vault }, { enabled: view }),
  ]);
  const box = useMemo(() => {
    return getv(qobox, "data.result");
  }, [qobox.dataUpdatedAt]);

  return (
    <>
      <div className="w-full">
        <Card className={twMerge("p-4 w-full overflow-hidden")}>
          <div className="relative w-[12rem] h-[12rem] mx-auto ">
            <Img img={boximg} className={"w-[12rem] h-[12rem] grayscale"} />
            <div className="absolute top-[30%] left-[20%] w-max">
              <p className="text-[1.5rem]  bg-acc0/40 p-2 rounded-md text-dark font-digi -rotate-[25deg]">
                Opened
              </p>
            </div>
          </div>
          <div className="fr-sc gap-2 w-full">
            {!nils(getv(open_dets, "tx_hash")) && (
              <>
                <Tag
                  redirect={`https://polygonscan.com/tx/${getv(
                    open_dets,
                    "tx_hash"
                  )}`}
                >
                  <Img className={"w-[1.5rem]"} img={polyimg} />
                </Tag>
              </>
            )}
            <div className="flex-1"></div>
            {qobox.isLoading && <Loader01c size="s" />}
            <Tag
              onClick={() => {
                if (!view) set_view(true);
                set_popup(true);
              }}
              className="text-acc0 -skew-x-12 border border-acc0 text-[0.8rem]"
            >
              View Loot
            </Tag>
          </div>
          <div className="fr-sc gap-2 w-full">
            <p className="my-2 text-[0.7rem] px-2">
              {iso_format(open_dets?.date, "MMM-DD 'YY, hh:mmA")}
            </p>
          </div>
        </Card>
        <PopUp
          wrapcn={twMerge("top-[5rem] left-[50%]")}
          innercn={twMerge(" translate-x-[-50%] translate-y-[0%]")}
          openstate={popup}
          overlayclose={true}
          onClose={() => set_popup(false)}
        >
          <LootBoxAssets box={box} loading={qobox.isLoading} />
        </PopUp>
      </div>
    </>
  );
};

export const LootBoxAssets = ({ box, loading = false }) => {
  if (loading) {
    return (
      <Card className={twMerge("max-w-[98vw] w-[60rem] p-4")}>
        <p className="text-center text-[1.2rem] text-red-400">
          Loading your Box
        </p>
        <Loader01c />
      </Card>
    );
  }

  if (nils(box) || _.isEmpty(box)) {
    return (
      <Card className={twMerge("max-w-[98vw] w-[60rem] p-4")}>
        <p className="text-center text-[1.2rem] text-red-400">
          Error Loading Your Box Assets
        </p>
      </Card>
    );
  }
  return (
    <>
      <Card className={twMerge("max-w-[98vw] w-[60rem] p-4")}>
        <>
          {_.isEmpty(box?.assets) && (
            <p className="texte-center">Boo! You got nothing in this box</p>
          )}
          <div className="grid xs:grid-cols-1 gap-2 flex-wrap">
            {(box?.assets || []).map((a) => {
              if (a.type == "fbike") return <BikeCard bike={getv(a, "data")} />;
              else if (a.type == "god")
                return (
                  <div className="p-2 bg-dark rounded-md ">
                    <div className="grid grid-cols-3">
                      <div className="col-span-1">
                        <Img
                          className={"w-full"}
                          img="https://imgur.com/lYds0vd.png"
                        />
                      </div>
                      <div className="col-span-2">
                        <p className="text-acc0 font-digi text-[1.5rem] mt-[2rem] ml-[2rem]">
                          $GOD NFT
                        </p>
                      </div>
                    </div>
                  </div>
                );
            })}
          </div>
        </>
      </Card>
    </>
  );
};
