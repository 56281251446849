const o = {
  getBoxTypeInfo:
    "function getBoxTypeInfo(uint256 boxtype) view returns (tuple(uint256 boxtype, string rarity, uint256 burn_credits, uint256 box_price))",
  burnSkin: "function burnSkin(uint256 skinid)",
  buyBox: "function buyBox(uint256 qty, uint256 boxtype)",
  openBox: "function openBox(uint256 boxtype)",
  ownsBoxes:
    "function ownsBoxes(address account, uint256 boxtype) view returns (uint256)",
};

export default o;
